import { snakeCase, isEmpty } from "lodash";
import { ParsedErrorResponse, requestJSON } from "./base";
import { DummyAccountInformation } from "./account";
import { GetServerSidePropsContext } from "next";
import {
  PostalAddressSchema,
  SnakeToCamelCaseNested,
  SubscriptionShippingWindow,
} from "@literati/kids-api";
import { mapKeysSnakeCase } from "utils";
import { SubscriptionPlanCamelCase } from "./subscription";

const BASE_URL = "/api/subscriptions-checkout";
const BOOK_CHECKOUT_URL = "/api/checkout";

export interface APIOptions {
  endpoint?: string;
}

export interface ChildInfo {
  firstName: string;
  birthMonth: number;
  birthYear: number;
}

export interface ShippingAddress {
  city: string;
  country: string;
  name: string;
  postalCode: string;
  state: string;
  street1: string;
  street2: string;
}

export interface ClubRecommendationResponse {
  age: number;
  age_on_border: boolean;
  primary_suggested_club: string;
  secondary_suggested_clubs: string[];
  possible_duplicate_sub_id: number;
}

export interface SelectedClubResponse {
  club: string;
}

export interface QuizClubResponse {
  club: string;
  reading_age: number;
}

export interface AddressInformation {
  name: string;
  street1: string;
  street2: string;
  city: string;
  postalCode: string; // string to allow for input of zip+4 codes
  state: string;
  phoneNumber: string;
  country: string;
}

export async function updateChildInfo({
  firstName,
  birthMonth,
  birthYear,
}: ChildInfo): Promise<ClubRecommendationResponse> {
  return await requestJSON(
    `${BASE_URL}/update-child`,
    {
      child_info: {
        first_name: firstName,
        birth_month: birthMonth,
        birth_year: birthYear,
      },
    },
    "post"
  );
}

export async function getSuggestedClubs(
  ctx?: GetServerSidePropsContext
): Promise<ClubRecommendationResponse> {
  return await requestJSON(`${BASE_URL}/suggested-clubs`, null, "get", { ctx });
}

export async function getSuggestedQuizClub(
  readingGrade: number
): Promise<QuizClubResponse> {
  return await requestJSON(
    `${BASE_URL}/suggested-quiz-club?readingGrade=${readingGrade}`,
    null,
    "get"
  );
}

export async function updateSelectedClub(
  clubSlug: string
): Promise<SelectedClubResponse> {
  return await requestJSON(
    `${BASE_URL}/select-club`,
    {
      club: clubSlug,
    },
    "post"
  );
}

export type AddPaymentMethodResponseCamelCase = {
  paymentMethodsOnFile: any[];
  errors: any;
  paymentMethod: string;
  braintree: any;
  stripePublishableKey: string;
  newPaymentMethodId: number;
};

export async function addPaymentMethod(
  paymentType: string,
  paymentToken: string,
  options: APIOptions = {}
) {
  const tokenKey = `${paymentType}_token`;
  const endpoint = options.endpoint || `${BASE_URL}/add-payment-method`;
  return await requestJSON(
    endpoint,
    {
      payment_information: {
        payment_method: paymentType,
        [tokenKey]: paymentToken,
      },
    },
    "post"
  );
}

/**
 * @TODO: export type from lib or update this type to match current api contract
 */
export interface Invoice {
  id: number;
  type: string;
  itemized: object;
  summaryTable: OrderSummary["orderSummary"];
  items: any[];
  shippingAddress: SnakeToCamelCaseNested<PostalAddressSchema>;
  orderValue: number;
}

export interface PurchaseSubscriptionInvoice {
  invoice: Invoice;
}

// this type may be inaccurate -- added types based off of
// existing implementation in SubscriptionsProvider
export type PurchaseSubscriptionResponse =
  | Array<PurchaseSubscriptionInvoice>
  | PurchaseSubscriptionInvoice;

export async function purchaseSubscription(
  experiments?: object,
  skipAddress?: boolean,
  redeemedGiftCode?: string
): Promise<PurchaseSubscriptionResponse> {
  return await requestJSON(
    `${BASE_URL}/purchase-subscription`,
    {
      experiments,
      skip_address: skipAddress,
      redeemed_gift_code: redeemedGiftCode,
    },
    "post"
  );
}

export interface OrderSummaryItem {
  type: string;
  label: string;
  value: string;
  listPrice: string;
  discount: unknown;
  valence: string;
  item: {
    type: string;
  };
  planSlug: string;
  clubSlug: string;
}

export interface OrderSummary {
  futurePromo: unknown;
  orderSummary: OrderSummaryItem[];
  promoError: unknown;
}

export async function getOrderSummary(
  ctx?: GetServerSidePropsContext,
  options: APIOptions = {}
): Promise<OrderSummary> {
  const endpoint = options.endpoint || `${BASE_URL}/order-summary`;
  return await requestJSON(endpoint, undefined, "get", {
    ctx,
  });
}

export async function addPromoCode(code: string) {
  return await requestJSON(`${BASE_URL}/add-promo`, { code }, "post");
}

export async function removePromoCode() {
  return await requestJSON(`${BASE_URL}/remove-promo`, undefined, "post");
}

export async function getPromos(
  codes: string[],
  ctx?: GetServerSidePropsContext
) {
  let query = "?";
  codes.forEach((code, idx) => {
    if (codes.length - 1 === idx) {
      query += `codes=${code}`;
    } else {
      query += `codes=${code}&`;
    }
  });

  return await requestJSON(`${BASE_URL}/get-promos${query}`, undefined, "get", {
    ctx,
  });
}

export type PromoCode = {
  amountOff: string | number;
  code: string;
  description: string;
  percentOff: unknown;
  productTypes: unknown;
};

export interface CheckoutSession {
  type?: string;
  giftCode?: string;
  selectedClub?: string;
  selectedPlan?: string;
  personalization?: ChildInfo;
  accountInfo?: DummyAccountInformation;
  shippingAddress?: ShippingAddress;
  defaultAddress?: ShippingAddress;
  defaultPaymentMethod?: any;
  items?: any[];
  auth?: any;
  currentItemId?: string;
  promoCode?: PromoCode | null;
}

export async function getCheckoutSession(
  ctx?: GetServerSidePropsContext
): Promise<CheckoutSession | ParsedErrorResponse> {
  const result: CheckoutSession = await requestJSON(
    `${BASE_URL}/session`,
    undefined,
    "get",
    {
      ctx,
    }
  );
  const session: CheckoutSession = {};
  const { defaultAddress, defaultPaymentMethod, auth } = result;
  const currentItem = result?.items?.[0] || {};
  session.currentItemId = currentItem.id || null;
  session.type = currentItem.type || "subscription";
  session.giftCode = currentItem.giftCode || null;
  session.selectedClub = currentItem.club || null;
  session.selectedPlan = currentItem.plan || null;
  session.personalization = currentItem.childInfo || null;
  session.shippingAddress = currentItem.shippingAddress || null;
  session.accountInfo = !isEmpty(auth) ? auth : null;
  session.promoCode = result?.promoCode || null;
  (session as any).defaultAddress = !isEmpty(defaultAddress)
    ? defaultAddress
    : null;
  session.defaultPaymentMethod = !isEmpty(defaultPaymentMethod)
    ? result.defaultPaymentMethod
    : null;
  return session;
}

export async function validateAddress({
  name,
  street1,
  street2,
  city,
  postalCode, // string to allow for input of zip+4 codes
  state,
  country,
}: AddressInformation) {
  return await requestJSON(
    `${BASE_URL}/validate-address`,
    {
      postal_address: {
        name,
        street1,
        street2: street2 || "",
        city,
        postal_code: postalCode,
        state,
        country,
      },
    },
    "post"
  );
}

export async function saveAddress(
  {
    name,
    street1,
    street2,
    city,
    postalCode, // string to allow for input of zip+4 codes
    state,
    country,
  }: AddressInformation,
  itemId?: string
) {
  const url = `/api/subscriptions-checkout/save-address${
    itemId ? `?id=${itemId}` : ""
  }`;

  return await requestJSON(
    url,
    {
      postal_address: {
        name,
        street1,
        street2,
        city,
        postal_code: postalCode,
        state,
        country,
      },
    },
    "post"
  );
}

export async function getOrderDetails(
  { id }: any,
  ctx?: GetServerSidePropsContext
) {
  return await requestJSON(
    `${BASE_URL}/order-details/${id}`,
    undefined,
    "get",
    { ctx }
  );
}

export async function submitSourceSurvey({ surveyResponse }: any) {
  return await requestJSON(
    `${BASE_URL}/source-survey`,
    {
      survey_response: surveyResponse,
    },
    "post"
  );
}

export async function getSubscriptionPlans(ctx?: GetServerSidePropsContext) {
  return (await requestJSON(
    `${BASE_URL}/subscription-plans`,
    undefined,
    "get",
    {
      ctx,
    }
  )) as SubscriptionPlanCamelCase[] | ParsedErrorResponse;
}

export type UpdateSelectedPlanResponseCamelCase = {
  plan: string;
  upgrade: boolean;
};

export async function updateSelectedPlan(
  { plan, subscription = null }: { plan: string; subscription?: unknown },
  ctx?: GetServerSidePropsContext
) {
  return await requestJSON(
    `${BASE_URL}/select-plan`,
    { plan, subscription },
    "post",
    {
      ctx,
    }
  );
}

export interface UpdateExistingPlanPayload {
  plan: string;
  subscription: unknown;
}

export async function updateExistingPlan(
  { plan, subscription }: UpdateExistingPlanPayload,
  ctx?: GetServerSidePropsContext
) {
  return await requestJSON(
    `${BASE_URL}/change-plan`,
    { plan, subscription },
    "post",
    {
      ctx,
    }
  );
}

export async function updateDeliverySchedule({
  schedule,
}: {
  schedule: string;
}) {
  return await requestJSON(
    `${BASE_URL}/select-schedule`,
    { schedule: snakeCase(schedule) },
    "post"
  );
}

export async function getShippingWindow(ctx?: GetServerSidePropsContext) {
  return (await requestJSON(`${BASE_URL}/shipping-window`, undefined, "get", {
    ctx,
  })) as SubscriptionShippingWindow | ParsedErrorResponse;
}

export async function previewPurchaseBooks(
  payload: {
    books: Array<{ bookId: number; status: string }>;
    couponCode?: string;
  },
  boxId: number,
  ctx?: GetServerSidePropsContext
) {
  const url =
    `${BOOK_CHECKOUT_URL}/box-shipments/${boxId}/purchase-books-preview` as const;
  const data = await requestJSON(url, mapKeysSnakeCase(payload), "post", {
    ctx,
  });
  return data;
}

export async function purchaseBooks(
  payload: {
    books: Array<{ bookId: number; status: string }>;
    couponCode?: string;
  },
  boxId: number
) {
  const url = `${BOOK_CHECKOUT_URL}/box-shipments/${boxId}/purchase-books`;
  const data = await requestJSON(url, mapKeysSnakeCase(payload), "post");
  return data;
}
