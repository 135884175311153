import Head from "next/head";

const JsonLDSchema = ({ jsonLDSchema }) => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(jsonLDSchema),
        }}
      />
    </Head>
  );
};

export default JsonLDSchema;
