const COLOR_SETS = {
  neo: {
    lighter: "#FFC176",
    default: "#FFAA43",
    darker: "#EA9024",
  },
  sprout: {
    lighter: "#68DACD",
    default: "#059281",
    darker: "#0B7568",
  },
  nova: {
    lighter: "#90C5DF",
    default: "#67B0D3",
    darker: "#60A3C3",
  },
  sage: {
    lighter: "#E1C2DF",
    default: "#CF9ECD",
    darker: "#BF97BD",
  },
  phoenix: {
    lighter: "#F39681",
    default: "#EF6F52",
    darker: "#E26346",
  },
  titan: {
    lighter: "#59ddd9",
    default: "#58cdc9",
    darker: "#399b98",
  },
  textGray: {
    lightest: "#9DA2A2",
    lighter: "#737373",
    default: "#4D4B4E",
    darker: "#242226",
  },
  blue: {
    default: "#1A77F3",
  },
  input: {
    lighter: "#F1E6F0",
    default: "#242226",
  },
};

Object.values(COLOR_SETS).forEach((v) => (v.DEFAULT = v.default));

module.exports = {
  // Defaults
  bgBase: "#FAFAFA",
  current: "currentColor",
  inherit: "inherit",
  white: "#fff",
  black: "#000",
  transparent: "transparent",
  // Literati colors
  teal: "#58CDC9",
  "teal-light": "#42e1dd",
  "teal-shade-2": "#32a8a4",
  "teal-shade-3": "#2A8C89",
  "ink-black": "#232323",
  "page-white": "#fcfcfc",
  perriwinkle: "#596ECA",
  "perriwinkle-light": "#8199ff",
  "perriwinkle-dark": "#3d4d82",
  coral: "#fd6c5f",
  cornflower: "#4698ca",
  ocean: "#004b87",
  sunrise: "#febd02",
  mint: "#aedcb6",
  mint3: "#E5F6F2",
  "mint-tint-1": "#BEE8DF",
  "mint-tint-4": "#F0FCF9",
  forest: "#086e6a",
  lavender: "#e2b0de",
  plum: "#892c5f",
  chartreuse: "#d3d646",
  gray1: "#f2f2f2",
  gray2: "#cecece",
  gray3: "#808083",
  gray4: "#67676a",
  gray5: "#414042",
  success: "#27AE60",
  "success-green": "#41B49D",
  caution: "#ffd04d",
  error: "#FF535D",
  disabled: "#EDEDED",
  "papier-blanc": "#FBFAF9",
  "pulp-white": "#f7f6f3",
  newsprint1: "#EDEBE6",
  newsprint2: "#E5E3DF",
  newsprint3: "#DBD9D5",
  newsprint4: "#cccac6",
  newsprint5: "#BCBBB7",
  "warm-gray-1": "#A3A2A0",
  "warm-gray-2": "#8C8B89",
  "warm-gray-3": "#72716F",
  "warm-gray-4": "#444343",
  "illuminating-green": "#059280",
  "french-blue": "#67AFD3",
  "french-blue-tint-4": "#E0F2FC",
  ...COLOR_SETS,
};
