import Head from "next/head";

export const GoogleAnalytics = ({ analyticsIDs }) => {
  const isDev = process.env.NODE_ENV === "development";
  const GTAG = isDev ? process.env.NEXT_PUBLIC_STAGING_GTAG : analyticsIDs.GTAG;

  if (!GTAG) {
    console.warn("Google Analytics tracking IDs not present.");
    return null;
  }
  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTAG}');`,
        }}
      />
      <noscript>
        <iframe
          title={"gtag"}
          src={`https://www.googletagmanager.com/ns.html?id=${GTAG}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
    </Head>
  );
};

/**
 * Initializes Facebook pixel.
 *
 * @note - This used to be included in _app.js, however we want to instantiate
 *         it on each page it's used, so that we can initialize with user data.
 *
 * @param {Object} args
 * @param {Object} args.analyticsIDs
 * @param {Object} args.initData - @see https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching
 * @param {Boolean} args.isProd
 */
export const FacebookAnalytics = ({ analyticsIDs, initData = {}, isProd }) => {
  if (analyticsIDs && !analyticsIDs.FACEBOOK_KIDS) {
    console.warn("Facebook tracking ID not present.");
    return null;
  }

  const pixelId = isProd
    ? analyticsIDs.FACEBOOK_KIDS
    : process.env.FACEBOOK_PIXEL_ID_DEV;
  const imgAlt = isProd ? "fb pixel" : "fb pixel dev";
  const initDataJson = JSON.stringify(initData);

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
          n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
              document,'script','https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${pixelId}', ${initDataJson});
          fbq('track', 'PageView');`,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`}
          alt={imgAlt}
        />
      </noscript>
    </>
  );
};
