export default {
  neo: {
    lighter: "#FFC176",
    default: "#FFAA43",
    darker: "#EA9024",
    labelColor: "#242226",
  },
  sprout: {
    lighter: "#68DACD",
    default: "#059281",
    darker: "#0B7568",
    labelColor: "#fff",
  },
  nova: {
    lighter: "#90C5DF",
    default: "#67B0D3",
    darker: "#60A3C3",
  },
  sage: {
    lighter: "#E1C2DF",
    default: "#CF9ECD",
    darker: "#BF97BD",
  },
  phoenix: {
    lighter: "#F39681",
    default: "#EF6F52",
    darker: "#E26346",
  },
  titan: {
    lighter: "#59ddd9",
    default: "#58cdc9",
    darker: "#399b98",
  },
  textGray: {
    lightest: "#9DA2A2",
    lighter: "#737373",
    default: "#4D4B4E",
    darker: "#242226",
  },
  blue: {
    default: "#1A77F3",
  },
  input: {
    lighter: "#F1E6F0",
    default: "#D7CAD6",
  },
  teal: {
    lighter: "#95DFDD",
    default: "#58cdc9",
    darker: "#47b3b0",
    labelColor: "#242226",
  },
  error: {
    default: "#FF535D",
  },
  inkBlack: {
    default: "#232323",
  },
};
