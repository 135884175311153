import { useContext, useEffect } from "react";
import {
  AmplitudeActiveExperimentKey,
  AmplitudeExperimentContext,
  ExposureFunction,
} from "contexts/AmplitudeExperimentProvider";

export interface UseExperimentArgs<VariantKey> {
  controlKey?: string;
  experimentKey: AmplitudeActiveExperimentKey;
  fallbackVariantKey?: VariantKey;
  shouldExposeUser?: (variant?: string) => boolean;
}

export type Experiment<VariantKey> = {
  exposure: ExposureFunction | undefined;
  isVariant: boolean;
  variant: VariantKey | undefined;
};

/**
 * Reusable hook for experiments.
 *
 * @note - presumes only 2 variants (control + variant)
 * @see AmplitudeExperimentProvider.tsx
 */
export function useExperiment<VariantKey>({
  controlKey = "control",
  experimentKey,
  fallbackVariantKey,
  shouldExposeUser,
}: UseExperimentArgs<VariantKey>): Experiment<VariantKey> {
  const experimentContext = useContext(AmplitudeExperimentContext);

  const fallbackKey = (fallbackVariantKey as string) || controlKey;

  // Gets the variant and does assignment based off of Device ID
  const variant = experimentContext?.variant(experimentKey, fallbackKey)?.value;

  useEffect(() => {
    if (variant) {
      const expose = shouldExposeUser ? shouldExposeUser(variant) : true;
      if (expose) {
        experimentContext?.exposure(experimentKey);
      }
    }
  }, [experimentContext, experimentKey, shouldExposeUser, variant]);

  const isControl = variant === controlKey;
  const isVariant = !isControl;

  return {
    exposure: experimentContext?.exposure,
    isVariant,
    variant: variant as VariantKey,
  };
}

export default useExperiment;
