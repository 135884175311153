import { isEmpty } from "lodash";
import { apiFetchJson } from "utils";
import { parseCookies } from "nookies";
import Cookies, { SetOption } from "cookies";
import { GetServerSidePropsContext } from "next";

import {
  mapKeysCamelCase,
  DropFirst,
  makeQueryString,
  SnakeToCamelCaseNested,
} from "@literati/kids-api";
import { URL_TYPES } from "@literati/kids-api";
import { ErrorResponseSchema } from "@literati/kids-api";

export let signedUserToken: string | null = null;

export type HttpMethod =
  | "GET"
  | "POST"
  | "PUT"
  | "DELETE"
  | "get"
  | "post"
  | "put"
  | "delete";

import { NEXTJS_BASE_PATH, NEXT_PUBLIC_KIDS_URL } from "globalConfig";

export type NextJSContext = GetServerSidePropsContext | undefined;
type NormalizedFieldErrors = {
  [key: string]: string;
};

interface UnparsedErrorResponse extends ErrorResponseSchema {
  fieldErrors?: NormalizedFieldErrors;
}

export interface ParsedErrorResponse extends UnparsedErrorResponse {
  parsed: true;
  error: UnparsedErrorResponse;
  status: number;
}

export interface UnexpectedErrorResponse {
  parsed: true;
  status: number;
  error: Response;
}

export type DefaultAPIResponse<T> = Promise<
  SnakeToCamelCaseNested<T> | ParsedErrorResponse
>;

export const setSignedUserToken = (token: string | null) => {
  if (typeof window !== "undefined") {
    signedUserToken = token;
  }
};

export const getDefaultHeaders = (
  method: HttpMethod = "GET",
  ctx: NextJSContext
) => {
  const cookies = ctx?.req?.headers?.cookie || "";
  const localCookies = parseCookies(ctx);

  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    cookie: cookies,
  });

  if (localCookies["access-token"]) {
    headers.set("Authorization", `Bearer ${localCookies["access-token"]}`);
  }

  const tokenQueryParameter = ctx?.query?.token;

  if (signedUserToken || tokenQueryParameter) {
    headers.append(
      "Literati-Signed-User-Token",
      signedUserToken ?? String(tokenQueryParameter)
    );
  }

  if (method.toUpperCase() !== "GET") {
    headers.append("X-CSRFToken", localCookies["csrftoken-kids"]);
  }

  return headers;
};

export const normalizeErrors = async (
  e: Response
): Promise<ParsedErrorResponse | UnexpectedErrorResponse> => {
  try {
    const errorJson: ParsedErrorResponse | ErrorResponseSchema = await e.json();
    if ("parsed" in errorJson) {
      /** make sure that this doesnt create a nested object when used in local api route */
      return errorJson;
    }
    const { ...err } = errorJson;
    const parsedErrors: UnparsedErrorResponse = { ...err } || {};

    if (errorJson["field_errors"] && !isEmpty(errorJson["field_errors"])) {
      parsedErrors.fieldErrors = {};
      Object.entries(errorJson["field_errors"]).map(([k, v]) => {
        if (!parsedErrors.fieldErrors) {
          return;
        }
        parsedErrors.fieldErrors[k] = v[0].message;
      });
    }
    return {
      error: parsedErrors,
      status: e.status,
      parsed: true,
    };
  } catch (err) {
    return { error: { ...e }, status: e.status, parsed: true };
  }
};

const refreshToken = async () =>
  requestJSONBasic(
    `${NEXT_PUBLIC_KIDS_URL}${NEXTJS_BASE_PATH}/api/refresh-token/`
  );

/** introduced this to perform only the request with a formatted json response */
export async function requestJSONBasic(
  url: string,
  data: object | undefined | null = undefined,
  method: HttpMethod = "GET",
  { ctx, mapKeys = true }: { ctx?: NextJSContext; mapKeys?: boolean } = {}
) {
  const headers = getDefaultHeaders(method, ctx);
  const options = {
    method,
    headers,
    body:
      data && method.toLowerCase() != "get" ? JSON.stringify(data) : undefined,
  };

  if (method.toLowerCase() == "get" && data) {
    url = url + makeQueryString(data as Record<string, string>);
  }

  const res = await apiFetchJson(url, options).catch((err) =>
    normalizeErrors(err)
  );

  return mapKeys ? mapKeysCamelCase(res) : res;
}

export async function requestJSON<K extends keyof URL_TYPES>(
  url: K | string,
  ...args: DropFirst<Parameters<typeof requestJSONBasic>>
): Promise<DefaultAPIResponse<URL_TYPES[K]>> {
  const localCookies = parseCookies();
  const loggedInSocial = !!localCookies["logged-in-social"];

  const res = await requestJSONBasic(url, ...args);

  const refreshAndRetry = async () => {
    const refreshRes = await refreshToken();
    if (refreshRes.loggedIn) {
      return requestJSONBasic(url, ...args);
    }
    return res;
  };

  if (
    loggedInSocial &&
    // whoami does not return a 401
    (res.status === 401 || (url.includes("/whoami") && !res.isAuthenticated))
  ) {
    return refreshAndRetry();
  }
  return res;
}

export const isAPIError = (
  r: Awaited<ReturnType<typeof requestJSON>>
): r is ParsedErrorResponse => {
  return typeof r == "object" && r !== null ? "error" in r : false;
};

const defaultGetUnauthorizedReturnValueFromError = (
  error: ParsedErrorResponse,
  ctx: NextJSContext
) => ({
  redirect: {
    destination: `/login?next=${ctx?.resolvedUrl}`,
    permanent: false,
  },
});

const defaultGetDefaultReturnValueFromError = (error: ParsedErrorResponse) => ({
  props: {
    error,
  },
});

const defaultHandleServerSideErrorOptions = {
  getUnauthorizedReturnValueFromError:
    defaultGetUnauthorizedReturnValueFromError,
  getDefaultReturnValueFromError: defaultGetDefaultReturnValueFromError,
};

/** Optional functions to provide to `handleServerSideError` to process the error that is returned */
interface HandleServerSideErrorOptions<R extends object | undefined> {
  getDefaultReturnValueFromError?: (
    error: ParsedErrorResponse,
    ctx: NextJSContext
  ) => R;
  getUnauthorizedReturnValueFromError?: (
    error: ParsedErrorResponse,
    ctx: NextJSContext
  ) => R;
}

type DefaultReturnType =
  | ReturnType<typeof defaultGetUnauthorizedReturnValueFromError>
  | ReturnType<typeof defaultGetDefaultReturnValueFromError>;

// this overload uses only the default functions for options
export function handleServerSideError(
  error: ParsedErrorResponse,
  ctx: NextJSContext,
  options?: HandleServerSideErrorOptions<DefaultReturnType>
): DefaultReturnType;
// this overload infers the return type for non-default functions provided to the options
export function handleServerSideError<R extends object | undefined>(
  error: ParsedErrorResponse,
  ctx: NextJSContext,
  options?: HandleServerSideErrorOptions<R>
): R;
// this implementation allows either overload (infers return type or uses DefaultReturnType)
export function handleServerSideError<
  R extends object | undefined | DefaultReturnType = DefaultReturnType
>(
  error: ParsedErrorResponse,
  ctx: NextJSContext,
  {
    getUnauthorizedReturnValueFromError = defaultHandleServerSideErrorOptions.getDefaultReturnValueFromError,
    getDefaultReturnValueFromError = defaultHandleServerSideErrorOptions.getUnauthorizedReturnValueFromError,
  }: HandleServerSideErrorOptions<
    R | DefaultReturnType
  > = defaultHandleServerSideErrorOptions
): R | DefaultReturnType {
  if (error.status == 401) {
    return getUnauthorizedReturnValueFromError(error, ctx);
  }
  // Customer is authenticated, but some other error has occurred.
  return getDefaultReturnValueFromError(error, ctx);
}

export const createValidationError = (message: string): ParsedErrorResponse => {
  return {
    error: {
      message,
    },
    status: 400,
    parsed: true,
  };
};

/**
 * Utility for coercing an "API" error
 * that conforms to a specific field.
 * Useful for default form validation handler
 */
export const createFieldError = (
  fieldName: string,
  message: string
): ParsedErrorResponse => {
  return {
    error: {
      fieldErrors: {
        [fieldName]: message,
      },
    },
    status: 400,
    parsed: true,
  };
};

/** for use during login and refresh */
export function setTokenCookies({
  accessToken,
  refreshToken,
  cookies,
}: {
  accessToken: string;
  refreshToken: string;
  cookies: Cookies;
}) {
  const baseOptions = {
    sameSite: "lax" as const,
    domain: process.env.COOKIE_DOMAIN,
  };
  const cookieOptions: SetOption = {
    httpOnly: true,
    ...baseOptions,
  };
  /** we set an exposed cookie to determine whether or not to attempt a token refresh */
  cookies.set("logged-in-social", "true", {
    httpOnly: false,
    ...baseOptions,
  });
  cookies.set("access-token", accessToken, cookieOptions);
  cookies.set("refresh-token", refreshToken, cookieOptions);
  return cookies;
}

export function clearTokenCookies(cookies: Cookies) {
  const options: SetOption = {
    sameSite: "lax",
    domain: process.env.COOKIE_DOMAIN,
  };
  cookies.set("logged-in-social", null, options);
  cookies.set("access-token", null, options);
  cookies.set("refresh-token", null, options);
  cookies.set("sessionid-kids", null, options);
}
