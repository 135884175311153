import { useContext } from "react";
import Head from "next/head";
import { get } from "lodash";
import { AdminContext } from "contexts/AdminProvider";

const Pinterest = () => {
  const { settings } = useContext(AdminContext);
  const pinterestTagId = get(settings, "ANALYTICS.PINTEREST");
  return !pinterestTagId ? null : (
    <Head>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            !function(e){if(!window.pintrk){window.pintrk=function(){window.pintrk.queue.push(
            Array.prototype.slice.call(arguments))};var
            n=window.pintrk;n.queue=[],n.version="3.0";var
            t=document.createElement("script");t.async=!0,t.src=e;var
            r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
            pintrk('load', ${pinterestTagId});
            pintrk('page');`,
        }}
        key="pinterest"
      />
    </Head>
  );
};

export default Pinterest;
