import useUtmSocialVisitor from "utils/hooks/useUtmSocialVisitor";
import useWindowStorage from "utils/hooks/useWindowStorage";
import useExperimentPrepaid from "utils/experiment/useExperimentPrepaid";

export const PENDING_COUPON_STORAGE_KEY = "PENDING_COUPON";

export default function useExcludeFromAnnual() {
  const [_, { getItem }] = useWindowStorage("localStorage");

  const { isUtmSocialVisitor } = useUtmSocialVisitor();

  const { isVariant: isPrepaidVariant } = useExperimentPrepaid();

  const pendingCoupon = !!getItem(PENDING_COUPON_STORAGE_KEY);

  const excludeFromAnnual =
    pendingCoupon || isUtmSocialVisitor || isPrepaidVariant;

  return {
    excludeFromAnnual,
    pendingCoupon,
    isUtmSocialVisitor,
  };
}
