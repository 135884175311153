import { apiPost } from "utils";

export const segmentEvent = async (api, name, properties) => {
  const endpointIndex = {
    subscriptions: "/api/subscriptions/trigger_segment_event",
  };

  const body = JSON.stringify({
    name: name,
    properties: properties,
  });

  await apiPost(endpointIndex[api], body);
};
