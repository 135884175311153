import { IconName } from "@literati/public-ui-shared-react";
import { UseSwipeParameters } from "components/bookShuffle/useSwipe";
import { Book } from "components/Book/bookTypes";
import { PreferenceValue } from "lib/api/onboarding";

export enum SwipeStatus {
  UNDECIDED = "undecided",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export const swipeStatusIconNameMap = {
  [SwipeStatus.ACCEPTED]: IconName.THUMBS_UP,
  [SwipeStatus.REJECTED]: IconName.THUMBS_DOWN,
};

/**
 * Limits decision to just accepted and rejected
 * (since those are the only statuses we represent with icons)
 */
export type BookShuffleDecisionType = keyof typeof swipeStatusIconNameMap;

/** These are passed through to the `useSwipe` hook inside of card components */
export type BookShuffleUseSwipeCardProps = Pick<
  UseSwipeParameters,
  | "onAnimateCycleComplete"
  | "onExitComplete"
  | "onStatusDecision"
  | "onSwipeStart"
  | "onSwipeEnd"
>;

export interface ShuffleBook extends Book {
  ownsBook?: boolean;
  preferenceValue?: PreferenceValue;
}
