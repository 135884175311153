import * as amplitude from "@amplitude/analytics-browser";

export const trackAmplitudeCancelAttempted = ({
  reason,
  subReason,
}: {
  reason: string;
  subReason?: string;
}) => {
  if (amplitude) {
    amplitude.track("Cancellation - Cancel Attempted", {
      "event source": "literati-next-kids",
      "page type": "Cancel",
      reason,
      subReason,
      path: window.location.pathname,
    });
  }
};

export const trackAmplitudeCancelConfirmed = ({
  reason,
  subReason,
}: {
  reason: string;
  subReason?: string;
}) => {
  if (amplitude) {
    amplitude.track("Cancellation - Subscription Cancelled", {
      "event source": "literati-next-kids",
      "page type": "Cancel",
      reason,
      subReason,
      path: window.location.pathname,
    });
  }
};
