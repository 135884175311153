import { Variant } from "@amplitude/experiment-js-client";
import { createContext, ReactNode, useCallback } from "react";
import { AmplitudeExperimentClient } from "utils/hooks/useAmplitudeExperimentInit";

export enum AmplitudeFlags {
  PROMO_CODE_SKIP_PLAN = "promo_code_skip_plan",
  SHOW_NEW_REDEEM_FLOW = "show_new_redeem_flow",
  SKIP_PLAN = "skip_plan",
  USE_ONE_MONTH_2025_PLAN = "use_one_month_2025_plan",
  UTM_SKIP_PLAN = "utm_skip_plan",
}
export type AmplitudeFlagKey = `${AmplitudeFlags}`;

export enum AmplitudeActiveExperiments {
  SSO = "sso",
  PREPAID_PLAN = "prepaid-v4",
}
export type AmplitudeActiveExperimentKey = `${AmplitudeActiveExperiments}`;

export enum SSOVariant {
  CONTROL = "control",
  VARIANT_SHOW_SSO = "show-sso",
}
export type SSOVariantKey = `${SSOVariant}`;

export enum PrepaidPlanVariant {
  CONTROL = "control",
  VARIANT_SHOW_PREPAID_FLOW = "show-variant-prepaid",
}
export type PrepaidPlanVariantKey = `${PrepaidPlanVariant}`;

export type VariantFunction = (
  key: string,
  fallback?: string | Variant
) => void | Variant;
export type ExposureFunction = (key: string) => void;

export type AmplitudeExperimentContextType = {
  amplitudeExperimentClient: AmplitudeExperimentClient;
  exposure: ExposureFunction;
  variant: VariantFunction;
};

export const AmplitudeExperimentContext =
  createContext<AmplitudeExperimentContextType | null>(null);

export type AmplitudeExperimentProviderProps = {
  children: ReactNode;
  value: AmplitudeExperimentClient;
};

/**
 * Used to expose the Analytics Experiment client globally.
 */
export const AmplitudeExperimentProvider = (
  props: AmplitudeExperimentProviderProps
) => {
  const { value: amplitudeExperimentClient, children } = props;

  /**
   * Provides a way to get variants indirectly from the client.
   * @note provides pathway for easier testing/mocking
   */
  const variant = useCallback(
    (key: string, fallback?: string | Variant) =>
      amplitudeExperimentClient?.variant(key, fallback),
    [amplitudeExperimentClient]
  );

  /**
   * Provides a way to call exposure indirectly from the client.
   * @note provides pathway for easier testing/mocking
   */
  const exposure = useCallback(
    (key: string) => amplitudeExperimentClient?.exposure(key),
    [amplitudeExperimentClient]
  );

  const value: AmplitudeExperimentContextType = {
    amplitudeExperimentClient,
    exposure,
    variant,
  };

  return (
    <AmplitudeExperimentContext.Provider value={value}>
      {children}
    </AmplitudeExperimentContext.Provider>
  );
};

export default AmplitudeExperimentProvider;
