import {
  AmplitudeActiveExperiments,
  PrepaidPlanVariantKey,
} from "contexts/AmplitudeExperimentProvider";
import useExperiment, {
  UseExperimentArgs,
} from "utils/experiment/useExperiment";

export type UseExperimentPrepaidArgs = Omit<
  UseExperimentArgs<PrepaidPlanVariantKey>,
  "experimentKey"
>;

export function useExperimentPrepaid(args?: UseExperimentPrepaidArgs) {
  const experimentData = useExperiment<PrepaidPlanVariantKey>({
    experimentKey: AmplitudeActiveExperiments.PREPAID_PLAN,
    ...args,
  });

  return experimentData;
}

export default useExperimentPrepaid;
