const colors = require("./colors");
const breakpoints = require("./breakpoints");
const { screens, media } = breakpoints;

const KUSTOMER_Z_INDEX = 2147483000;

module.exports = {
  colors,
  screens,
  media,
  KUSTOMER_Z_INDEX,
};
