import { makeUrl } from "utils";
import PropTypes from "prop-types";
import Head from "next/head";

/**
 * @param {Object} props
 * @param {Object} props.openGraph
 * @param {string} props.openGraph.meta_description
 * @param {string} props.openGraph.og_title
 * @param {string} props.openGraph.og_description
 * @param {string[]} props.openGraph.images
 */
const OpenGraph = ({ openGraph }) => {
  return (
    <Head>
      <meta
        name="description"
        content={openGraph.meta_description}
        key="description"
      />
      <meta property="og:title" content={openGraph.og_title} key="og:title" />
      <meta
        name="twitter:title"
        content={openGraph.og_title}
        key="twitter:title"
      />
      <meta
        property="og:description"
        content={openGraph.og_description}
        key="og:description"
      />
      <meta
        name="twitter:description"
        content={openGraph.og_description}
        key="twitter:description"
      />
      {openGraph?.images && openGraph.images[0] && (
        <>
          <meta
            property="og:image"
            content={makeUrl(openGraph.images[0])}
            key="og:image"
          />
          <meta
            name="twitter:image"
            content={makeUrl(openGraph.images[0])}
            key="twitter:image"
          />
        </>
      )}
      <meta name="twitter:site" content="@literati" key="twitter:site" />
      <meta name="twitter:card" content="summary" key="twitter:card" />
      <meta property="fb:app_id" content="1822602924674761" key="fb:app_id" />
    </Head>
  );
};

OpenGraph.propTypes = {
  openGraph: PropTypes.shape({
    meta_description: PropTypes.string.isRequired,
    og_title: PropTypes.string.isRequired,
    og_description: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default OpenGraph;
