// Determine if user was directed to webapp via a native app

import { useRouter } from "next/router";
import React, { useContext, createContext, useState, useEffect } from "react";

const PlatformProviderContext = createContext({
  platform: null,
  isAndroidApp: false,
  isIosApp: false,
  isNativeApp: false,
});

// Pull query params out of path
// next router doesn't populate router.query object during SSR,
// but does expose the querystring in the full path object, so
// we opt to parse it ourselves for reliability.
const getQueryParam = (path, queryParam) => {
  const res =
    path.match(new RegExp(`[?&]${queryParam}(=([^&#]*)|&|#|$)`)) || [];
  return res[2];
};

const PLATFORM_STORAGE_KEY = "Launched_By_Native_App";

// For SSR when window.sessionStorage is not available
const fakeStorage = {
  getItem: () => null,
  setItem: () => null,
  removeItem: () => null,
};

PlatformProviderContext.displayName = "PlatformContext";

export const usePlatform = () => useContext(PlatformProviderContext);

export const PlatformProvider = ({ children }) => {
  const [savedPlatform, setSavedPlatform] = useState(null);

  const router = useRouter();
  const sessionStorage =
    typeof window === "undefined" ? fakeStorage : window.sessionStorage;
  const param = getQueryParam(router.asPath, "platform");

  useEffect(() => {
    if (param?.length > 0 && param !== savedPlatform) {
      // Is there a query string param that differes from the previously known platform value
      setSavedPlatform(param);
      sessionStorage.setItem(PLATFORM_STORAGE_KEY, param);
    } else if (!savedPlatform) {
      // otherwise, if there is no previous value known, check session storage
      const platform = sessionStorage.getItem(PLATFORM_STORAGE_KEY);
      if (platform?.length > 0) {
        setSavedPlatform(platform);
      }
    }
  }, [param, sessionStorage, savedPlatform]);

  let result = {
    platform: savedPlatform,
    isIosApp: savedPlatform?.toLowerCase() === "ios",
    isAndroidApp: savedPlatform?.toLowerCase() === "android",
    isNativeApp: undefined,
  };
  result.isNativeApp = result.isIosApp || result.isAndroidApp;

  return (
    <PlatformProviderContext.Provider value={result}>
      {children}
    </PlatformProviderContext.Provider>
  );
};
