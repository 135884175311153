export const ALLOWED_HOSTS = [
  "auth.literati.com",
  "auth.literati.dev",
  "efairs.literati.com",
  "efairs.literati.dev",
  "efairsstg.wpengine.com",
  "efairsdev.wpengine.com",
  "kids.literati.com",
  "kids.literati.dev",
  "kids.literati.wtf",
  "literati.com",
  "literati.dev",
  "literati.wtf",
];

export const DEFAULT_PROMO_CODE = "BF2020";
// relative links should not include /kids
export const LINKS = {
  index: "/",
  activation: "/activation/",
  membership: "/membership/",
  bookshelfQuiz: "/quiz",
  subscribe: "/subscribe/",
  giveGift: "/gifts/",
  giveSubscription: "/give-subscription/",
  redeemGift: "/redeem/",
  login: "/login/",
  logout: "/logout/",
  terms: "/terms/",
  privacy: "/privacy",
  faq: "https://help.literati.com/",
  contact: "/contact/",
  blog: "https://literati.com/best-kids-books/",
  illustrators: "/featured-illustrators/",
  donations: "/donate/",
  bookFairs: "https://literati.com/bookfairs/",
  careers: "/careers/",
  shuffle: "/onboarding/book-shuffle/",
  literatiExtraCredit: "/teachers/",
  boxes: "/account/boxes/",
  subscriptions: "/account/subscription/",
  referrals: "/account/account-details#referrals",
  account: "/account/account-details/",
  orders: "/account/billing-history/",
  reviews: "/reviews/",
  resetPassword: "https://literati.com/password/reset/",
  teacherDashboard: "/teachers/dashboard",
  classroom: "/classroom",
  extraCredit: "/extra-credit",
  onboardingConfirmation: "/onboarding/confirmation",
  eFair: "https://efairs.literati.com/",
  openBook: "https://openbook.literati.com/login",
  kids: "https://literati.com/kids",
  readingLevelsParentGuide: "https://literati.com/reading-levels-parent-guide/",

  // SOCIAL
  facebook: "https://www.facebook.com/literatikidsclub",
  pinterest: "https://www.pinterest.com/literatikids/",

  // CLUBS (deprecated)
  neo: "/clubs/club-neo/",
  sprout: "/clubs/club-sprout/",
  nova: "/clubs/club-nova/",
  sage: "/clubs/club-sage/",
  phoenix: "/clubs/club-phoenix/",
  titan: "https://join.literati.com/clubtitan",

  referralContest:
    "https://join.literati.com/referrals-competition-leaderboard-2020/",
  contactForm: "https://literati.com/contact/",
  quiz: "/subscribe/quiz/",
  kidsAppstore: "https://itunes.apple.com/us/app/literati-kids/id1555772709",
  kidsGoogleStore:
    "https://play.google.com/store/apps/details?id=com.literati.kids",
  schoolbox: "/schoolbox/",

  // PRODUCT PAGES
  dreamer:
    "https://literati.com/kids-book-club/babies-toddlers/dreamer-reading-level/",
  seeker:
    "https://literati.com/kids-book-club/babies-toddlers/seeker-reading-level/",
  stargazer:
    "https://literati.com/kids-book-club/babies-toddlers/stargazer-reading-level/",
  discoverer:
    "https://literati.com/kids-book-club/babies-toddlers/discoverer-reading-level/",
  scout:
    "https://literati.com/kids-book-club/preschool-kindergarten-1st-2nd-grades/scout-reading-level/",
  tinkerer:
    "https://literati.com/kids-book-club/preschool-kindergarten-1st-2nd-grades/tinkerer-reading-level/",
  ranger:
    "https://literati.com/kids-book-club/preschool-kindergarten-1st-2nd-grades/ranger-reading-level/",
  voyager:
    "https://literati.com/kids-book-club/preschool-kindergarten-1st-2nd-grades/voyager-reading-level/",
  adventurer:
    "https://literati.com/kids-book-club/3rd-4th-5th-grades/adventurer-reading-level/",
  navigator:
    "https://literati.com/kids-book-club/3rd-4th-5th-grades/navigator-reading-level/",
  aviator:
    "https://literati.com/kids-book-club/3rd-4th-5th-grades/aviator-reading-level/",
  trailblazer:
    "https://literati.com/kids-book-club/6th-7th-8th-grades/trailblazer-reading-level/",
  wizard:
    "https://literati.com/kids-book-club/6th-7th-8th-grades/wizard-reading-level/",
  legend:
    "https://literati.com/kids-book-club/6th-7th-8th-grades/legend-reading-level/",
};

export const PHONE = {
  number: "833-548-5683",
  word: "833-LIT-LOVE",
};

// these links are ported and should be ignored by makeUrl
export const PORTED_LINKS = [
  LINKS.boxes,
  LINKS.giveGift,
  LINKS.subscribe,
  LINKS.reviews,
  LINKS.terms,
  LINKS.privacy,
  LINKS.illustrators,
  LINKS.account,
  LINKS.orders,
  LINKS.referrals,
  LINKS.subscriptions,
  LINKS.login,
  LINKS.logout,
  LINKS.redeemGift,
  `/subscribe/?extCouponCode=${DEFAULT_PROMO_CODE}`,
];

// https://literati.kustomerapp.com/app/settings/assistants
export const KUSTOMER_RETENTION_ASSISANT = "6177066b7aa900001a1477fe";

export const KUSTOMER_QUEUE_KEYS = {
  cancellation: "retention",
  general: "chat",
  engineering: "engineering",
};

export const CLUBS = ["neo", "sprout", "nova", "sage", "phoenix", "titan"].map(
  (c) => `club-${c}`
);

export const FREQUENCY_OPTIONS = [
  {
    label: "Every month",
    value: "monthly",
    skipDuration: [0, 1, 0],
    description: "every month",
  },
  {
    label: "Every 2 months",
    value: "every_other",
    skipDuration: [0, 2, 0],
    description: "every 2 months",
  },
  {
    label: "Every 3 months",
    value: "quarterly",
    skipDuration: [0, 3, 0],
    description: "every 3 months",
  },
] as const;
export type FrequencyOption = typeof FREQUENCY_OPTIONS[number];
export const NEXTJS_BASE_PATH = process.env.NEXTJS_BASE_PATH;
export const NEXT_PUBLIC_KIDS_URL = process.env.NEXT_PUBLIC_KIDS_URL;

export const BASE_FILE_PATH = __dirname.includes(".next")
  ? process.cwd()
  : __dirname;
