import { createContext, useCallback, useEffect, useState } from "react";
import { get, isEqual, omit } from "lodash";
import { segmentEvent } from "utils/segment";
import useSWR from "swr";
import { formatToast } from "../utils";
import { useToasts } from "react-toast-notifications";
import { useRouter } from "next/router";
import { format } from "url";
import { requestJSON } from "../lib/api/base";
import useWindowStorage from "utils/hooks/useWindowStorage";
import { PENDING_COUPON_STORAGE_KEY } from "utils/hooks/useExcludeFromAnnual";

export const DEFAULT_STATE = {
  featureFlags: [],
  siteBanners: [],
  settings: { FACEBOOK_OAUTH_CLIENT_ID: "" },
  isFeatureOn: (_) => false,
};

export const AdminContext = createContext(DEFAULT_STATE);

export const AdminProvider = ({
  initSiteBanners,
  initFeatureFlags,
  initSettings,
  children,
}) => {
  const [_, { setItem }] = useWindowStorage("localStorage");

  // Passing the initial data called in Apps will prevent the call from re-running and the component re-rendering if the data has not changed
  const { data: featureFlagsData, mutate: refreshFeatureFlags } = useSWR(
    "/api/feature-flags/",
    requestJSON,
    {
      initialData: initFeatureFlags,
    }
  );
  const { data: siteBannersData } = useSWR("/api/site-banners/", {
    initialData: initSiteBanners,
  });

  const siteBanners = get(siteBannersData, "site_banners", []);
  const featureFlags = get(featureFlagsData, "featureFlags");

  const isFeatureOn = (name) =>
    featureFlags
      ? Boolean(featureFlags.find((feature) => feature.name === name))
      : null;

  /* SETTINGS dynamicValues: will change but are not being consumed */
  const dynamicValues = [];
  const staticOnly = (data) => omit(data, dynamicValues);
  const compareStaticOnly = (a, b) => {
    return isEqual(staticOnly(a), staticOnly(b));
  };
  const { data } = useSWR("/api/settings/", {
    initialData: staticOnly(initSettings),
    compare: compareStaticOnly,
  });

  /* return static only to trigger errors if a developer tries to use a non-compared value */
  const settings = staticOnly(data);

  /* MESSAGES */
  const router = useRouter();
  const { query } = router;
  const messagesURL = format({ pathname: "/api/messages/", query });
  const fetchMessages = useCallback(() => requestJSON(messagesURL), [query]);
  const [refreshingMessages, setRefreshingMessages] = useState(false);

  const { data: messagesData, mutate: refreshMessages } = useSWR(
    query?.extCouponCode || query?.extAffiliateCode
      ? messagesURL
      : "api.getMessages",
    {
      fetcher: fetchMessages,
    }
  );
  const messages = get(messagesData, "messages", []);
  messages?.forEach((m) => {
    if (m.message.includes("coupon code")) {
      setItem(PENDING_COUPON_STORAGE_KEY, true);
    }
  });
  const { addToast } = useToasts();

  useEffect(() => {
    async function toastMessages() {
      if (messages.length && !refreshingMessages) {
        messages.forEach(({ message, levelTag }) => {
          addToast(...formatToast(message, { appearance: levelTag }));
        });
        /* reset the cache */
        setRefreshingMessages(true);
        await refreshMessages([]);
        setRefreshingMessages(false);
      }
    }

    toastMessages();
    // intentionally incomplete dependency list to prevent multiple toasts for the same message
    // @TODO: re-implement this file without SWR and proper state checks
  }, [messages, addToast]);

  const [showQuiz, setShowQuiz] = useState(false);

  const value = {
    siteBanners,
    featureFlags,
    isFeatureOn: isFeatureOn || (() => false),
    segmentEvent,
    settings,
    refreshMessages,
    showQuiz,
    setShowQuiz,
    refreshFeatureFlags,
  };

  if (typeof window !== "undefined") {
    window._SETTINGS = settings;
  }

  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};

export default AdminProvider;
