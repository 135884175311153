import { Reducer, useReducer } from "react";

function useReducerFromObject<
  ReducersKey extends string | number | symbol,
  State,
  Action extends { type: ReducersKey } = {
    type: ReducersKey;
  } & Partial<State>
>(
  reducers: { [K in ReducersKey]: (state: State, action: Action) => State },
  defaultState: State
) {
  const rootReducer: Reducer<State, Action> = (
    state = defaultState,
    action
  ) => {
    if (reducers[action.type]) {
      return reducers[action.type](state, action);
    }
    return state;
  };
  return useReducer(rootReducer, defaultState);
}

export default useReducerFromObject;
