import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import useSWR from "swr";
import noop from "lodash/noop";
import * as accountApi from "lib/api/account";
import { addPaymentMethod as addMethod } from "lib/api/checkout";
import analytics from "analytics";
import useResponseParser from "utils/hooks/useResponseParser";
import { attemptCancellation, confirmCancellation } from "lib/api/subscription";
import { AuthContext } from "contexts/AuthProvider";
import { AmplitudeExperimentContext } from "contexts/AmplitudeExperimentProvider";

export const AccountContext = createContext({
  addPaymentMethod: "",
  auth: {
    isAuthenticated: false,
  },
  availableCredit: false,
  createAccount: noop,
  defaultPaymentMethod: {
    type: "",
    brand: "",
    description: "",
    current: false,
    last4: "",
  },
  login: noop,
  paymentMethodsLoaded: false,
  outstandingBalance: false,
  refreshPaymentMethods: noop,
  queryToken: null,
  setQueryToken: noop,
  updateUserProfile: noop,
});

export const AccountProvider = ({ children }) => {
  // forwarding login through this context for compatibility
  const {
    auth,
    refreshAuth,
    loginWithSession: login,
    onLoginSuccess,
  } = useContext(AuthContext);
  const [queryToken, setQueryToken] = useState(null);

  const { data: paymentMethodsRes, mutate: refreshPaymentMethods } = useSWR(
    auth?.isAuthenticated ? "accountApi.getPaymentMethods" : null,
    accountApi.getPaymentMethods
  );

  const { accountBalance, paymentMethods } = paymentMethodsRes || {};

  const parseResponse = useResponseParser();

  const createAccount = useCallback(
    async (
      accountInformation,
      successMessage = null,
      toastOnError = false,
      toastOnSuccess = false
    ) => {
      const res = await accountApi.createAccount(accountInformation);
      const onSuccess = async () => {
        onLoginSuccess();
        window?.scrollTo({ top: 0 });
      };
      return parseResponse({
        res,
        onSuccess,
        successMessage,
        toastOnError,
        toastOnSuccess,
      });
    },
    [parseResponse, onLoginSuccess]
  );

  const updateUserProfile = useCallback(
    async (profile, { successMessage }, toastOnError = false) => {
      const res = await accountApi.updateProfile(profile);
      const onSuccess = async () => await refreshAuth();
      return parseResponse({ res, onSuccess, successMessage, toastOnError });
    },
    [parseResponse, refreshAuth]
  );

  const changePassword = async ({ values, onSuccess }) => {
    const res = await accountApi.changePassword(values);
    return parseResponse({
      res,
      onSuccess,
      successMessage: "Successfully updated password",
    });
  };

  const addPaymentMethod = async ({
    paymentType,
    paymentToken,
    onSuccess,
    toastOnError,
    successMessage,
    onError,
  }) => {
    const res = await addMethod(paymentType, paymentToken);
    const handleSuccess = () => {
      onSuccess && onSuccess(res);
      refreshPaymentMethods();
    };
    return parseResponse({
      res,
      onSuccess: handleSuccess,
      toastOnError,
      successMessage: successMessage || "Billing information updated",
      onError: ({ error }) => onError(error),
    });
  };

  const paymentMethodsLoaded = Array.isArray(paymentMethods);
  const defaultPaymentMethod = paymentMethodsLoaded && paymentMethods[0];
  const noPaymentMethod = paymentMethodsLoaded && !defaultPaymentMethod;
  const hasBalance = accountBalance !== "$0.00";
  const outstandingBalance =
    accountBalance?.startsWith("-") && hasBalance && accountBalance;
  const availableCredit = !outstandingBalance && hasBalance && accountBalance;

  const amplitudeExperimentContext = useContext(AmplitudeExperimentContext);
  const amplitudeExperimentClient =
    amplitudeExperimentContext?.amplitudeExperimentClient;

  const value = {
    ...auth,
    auth,
    createAccount,
    login,
    refreshAuth,
    updateUserProfile,
    defaultPaymentMethod,
    outstandingBalance,
    availableCredit,
    paymentMethodsLoaded,
    noPaymentMethod,
    changePassword,
    addPaymentMethod,
    attemptCancellation,
    confirmCancellation,
    refreshPaymentMethods,
    queryToken,
    setQueryToken,
  };

  useEffect(() => {
    if (auth) {
      analytics.userAuthenticated(auth);

      if (auth.user?.amplitudeId) {
        analytics.setAmplitudeUserId(auth.user.amplitudeId);
        amplitudeExperimentClient?.fetch();
      }
    }
  }, [auth]);

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

export default AccountProvider;
