import { SWRConfig } from "swr";
import React, { useEffect } from "react";
import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import App from "next/app";
import getConfig from "next/config";
import { useRouter } from "next/router";
import "globalStyles/before.scss";
import "globalStyles/main.scss";
import "globalStyles/after.scss";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import Favicons from "components/Layout/Favicons";
import Head from "next/head";
import OpenGraph from "components/Layout/OpenGraph";
import JsonLDSchema from "components/Layout/JsonLDSchema";
import {
  FacebookAnalytics,
  GoogleAnalytics,
} from "components/Layout/Analytics";
import Pinterest from "components/Layout/Pinterest.js";
import KustomerChatEntry from "components/Layout/Kustomer.js";
import Snap from "components/Layout/Snap.js";
import Profitwell from "components/Layout/Profitwell.js";
import { apiFetchJson } from "utils";
import { ToastProvider } from "react-toast-notifications";
import Toast from "components/Layout/Messages/Toast";
import { PlatformProvider } from "contexts/PlatformProvider";
import AdminProvider from "contexts/AdminProvider";
import AccountProvider from "contexts/AccountProvider";
import CheckoutProvider from "contexts/CheckoutProvider";
import KustomerProvider from "contexts/KustomerProviderV2";
import theme from "../theme";
import DefaultThemeProvider from "components/Layout/DefaultThemeProvider";
import dynamic from "next/dynamic";
import AuthProvider from "../contexts/AuthProvider";
import * as amplitude from "@amplitude/analytics-browser";
import analytics from "analytics";
import { getCheckoutSession } from "lib/api/checkout";
import useAmplitudeExperimentInit from "utils/hooks/useAmplitudeExperimentInit";
import { AmplitudeExperimentProvider } from "contexts/AmplitudeExperimentProvider";
import useExcludeFromAnnual from "utils/hooks/useExcludeFromAnnual";

const ClientProxyWarning = dynamic(() => import("@/account/ProxyWarning"), {
  ssr: false,
});

if (process.env.SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.STAGE_NAME,
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const assetPrefix = process.env.ASSET_PREFIX;

// tag manager is configured to respect this dataLayer push
export function reportWebVitals(metric) {
  const event = "Metric Measured";
  const eventLabel = metric.name;
  let eventValue = 0;
  switch (eventLabel) {
    case "Next.js-hydration":
      eventValue = metric.value + metric.startTime;
      break;
    case "LCP":
    case "FID":
    case "CLS":
    case "TTFB":
      eventValue = metric.value;
      break;
    default:
      // don't track other metrics at this time
      return;
  }
  window.dataLayer?.push({
    event,
    eventLabel,
    eventValue,
  });
}

// Init Amplitude
if (process.env.NEXT_PUBLIC_AMPLITUDE_KEY && typeof window !== "undefined") {
  amplitude.init(`${process.env.NEXT_PUBLIC_AMPLITUDE_KEY}`, null, {
    defaultTracking: {
      attribution: true,
      pageViews: false,
      sessions: true,
      formInteractions: false,
      fileDownloads: true,
    },
  });
}

const MyApp = ({
  Component,
  pageProps,
  settings,
  siteBanners,
  featureFlags,
  isProd,
  checkoutSession,
  amplitudeDeploymentKey,
}) => {
  const router = useRouter();
  const getLayout = Component.getLayout || ((page) => <>{page}</>);

  useExcludeFromAnnual();

  const { amplitudeExperimentClient, amplitudeExperimentClientInitialized } =
    useAmplitudeExperimentInit({
      deploymentKey: amplitudeDeploymentKey,
    });

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (typeof window === "undefined") return;
      /* reset scroll */
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });

      // Track page views on route completion for analytics
      analytics.trackAmplitudePageView(url);

      const googleAnalyticsId = settings.ANALYTICS.GA;
      if (!googleAnalyticsId || !window.gtag) return;
      window.gtag("config", googleAnalyticsId, { page_path: url });
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, settings]);

  useEffect(() => {
    // Track initially loaded page as a page view
    analytics.trackAmplitudePageView();

    // Send any UTM Parameters to Amplitude
    analytics.setAmplitudeUTMParameters();
  }, []);

  return (
    <ErrorBoundary>
      <DefaultThemeProvider theme={theme}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
          />
          <link rel="preconnect" href={assetPrefix} />
          <link rel="preconnect" href="https://analytics.google.com" />
          <link
            rel="preload"
            href={`${assetPrefix}/static/fonts/avenir/Avenir-Medium.woff2`}
            as="font"
            type="font/woff2"
            crossOrigin=""
          />
          <link
            rel="preload"
            href={`${assetPrefix}/static/fonts/hoefler/HoeflerTxt-Roman.woff2`}
            as="font"
            type="font/woff2"
            crossOrigin=""
          />
          <link
            rel="preload"
            href={`${assetPrefix}/static/fonts/hoeflerTitling/HoeflerTitling-Bold.woff2`}
            as="font"
            type="font/woff2"
            crossOrigin=""
          />
          <link
            rel="preload"
            href={`${assetPrefix}/static/fonts/avenir/Avenir-Heavy.woff2`}
            as="font"
            type="font/woff2"
            crossOrigin=""
          />
          <FacebookAnalytics
            analyticsIDs={settings.ANALYTICS}
            initData={{ em: checkoutSession?.accountInfo?.email }}
            isProd={isProd}
          />
        </Head>
        <Favicons favicons={settings.FAVICONS} />
        <OpenGraph openGraph={settings.OPEN_GRAPH} />
        <JsonLDSchema jsonLDSchema={settings.JSON_LD_SCHEMA} />
        <GoogleAnalytics analyticsIDs={settings.ANALYTICS} />
        <KustomerChatEntry />
        <SWRConfig
          value={{
            fetcher: (path) => apiFetchJson(path),
            refreshWhenOffline: true,
            revalidateOnReconnect: true,
            revalidateOnMount: true,
          }}
        >
          <ToastProvider
            autoDismiss
            autoDismissTimeout={5000}
            components={{ Toast }}
          >
            <AdminProvider
              initSiteBanners={siteBanners}
              initFeatureFlags={featureFlags}
              initSettings={settings}
            >
              <PlatformProvider>
                <AmplitudeExperimentProvider value={amplitudeExperimentClient}>
                  <AuthProvider>
                    <AccountProvider>
                      <KustomerProvider>
                        <CheckoutProvider>
                          <Snap />
                          <Pinterest />
                          <Profitwell />
                          <ClientProxyWarning />
                          {amplitudeExperimentClientInitialized &&
                            getLayout(<Component {...pageProps} />)}
                        </CheckoutProvider>
                      </KustomerProvider>
                    </AccountProvider>
                  </AuthProvider>
                </AmplitudeExperimentProvider>
              </PlatformProvider>
            </AdminProvider>
          </ToastProvider>
        </SWRConfig>
      </DefaultThemeProvider>
    </ErrorBoundary>
  );
};

MyApp.getInitialProps = async (appContext) => {
  const { ctx } = appContext;
  // Make these calls server side first to avoid flicker
  const [settings, siteBanners, featureFlags, checkoutSession] =
    await Promise.all([
      apiFetchJson("/api/settings/"),
      apiFetchJson("/api/site-banners/"),
      apiFetchJson("/api/feature-flags/"),
      getCheckoutSession(ctx),
    ]);

  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return {
    ...appProps,
    settings,
    siteBanners,
    featureFlags,
    isProd: process.env.PROD === "1",
    checkoutSession,
    amplitudeDeploymentKey: process.env.AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY,
  };
};

export default MyApp;
