import { createContext, ReactNode, useMemo } from "react";
import { v4 as uuidV4 } from "uuid";

export const ScopedStorageContext = createContext<{
  scopePrefix: string;
}>({
  scopePrefix: "",
});

interface ScopedStorageProps {
  children?: ReactNode;
  scopePrefix?: string;
}
/**
 * Used to to scope useWindowStorage to a specific instance
 */
export const ScopedStorageProvider = ({
  children,
  scopePrefix = "",
}: ScopedStorageProps) => {
  /*  Note: these values MUST be memoized to make sure that 
  the consuming `useWindowStorageMock` hooks always
  access the EXACT same object */
  const scopePrefiUID = useMemo(uuidV4, []);
  scopePrefix = scopePrefix || scopePrefiUID;
  const value = useMemo(
    () => ({
      scopePrefix,
    }),
    [scopePrefix]
  );

  return (
    <ScopedStorageContext.Provider value={value}>
      {children}
    </ScopedStorageContext.Provider>
  );
};

export default ScopedStorageProvider;
