import {
  useEffect,
  useReducer,
  useContext,
  useMemo,
  useCallback,
  useState,
} from "react";
import { KUSTOMER_QUEUE_KEYS, KUSTOMER_RETENTION_ASSISANT } from "globalConfig";
import { useGeneralChatOnline } from "utils/hooks/chat";
import { AdminContext } from "contexts/AdminProvider";
import { useRouter } from "next/router";
import KustomerChatClient, {
  ChatUser,
  ConversationAttributes,
  logout,
} from "@literati/kustomer-chat-client";
import { isEqual } from "lodash";
import * as Sentry from "@sentry/node";
import { AuthContext } from "contexts/AuthProvider";

const ACTION_DELETE = "ACTION_DELETE";
const ACTION_CREATE = "ACTION_CREATE";

/**
 * Determine which customer support chat queue a chat should be routed to.
 */
const getQueueKeyStr = (settings, isCancelPage) => {
  let queueKeyStr;
  // send test chats to the engineering queue, not the actual retention queue
  if (
    process.env.NODE_ENV === "development" ||
    settings["KUSTOMER_USE_DEV_CHAT_QUEUE"]
  ) {
    queueKeyStr = KUSTOMER_QUEUE_KEYS["engineering"];
  } else {
    queueKeyStr =
      KUSTOMER_QUEUE_KEYS[isCancelPage ? "cancellation" : "general"];
  }
  return queueKeyStr;
};

const createConversationAttributes = (
  isCancelPage,
  settings,
  user,
  selectedCategoryStr = null
) => {
  if (!settings || !user) {
    return null;
  }

  const chatUser = new ChatUser(
    user.id,
    user.firstName,
    user.lastName,
    user.email,
    user.chatJwtToken
  );
  const sourceEnvStr = settings["KUSTOMER_CHAT_ENV"];
  const queueKeyStr = getQueueKeyStr(settings, isCancelPage);
  const extra = selectedCategoryStr && { selectedCategoryStr };

  const conversationAttributes = new ConversationAttributes(
    chatUser,
    sourceEnvStr,
    queueKeyStr,
    extra
  );

  return conversationAttributes;
};

const kustomerChatClientReducer = (kustomerChatClient, action) => {
  if (action.type === ACTION_DELETE) {
    if (kustomerChatClient) {
      kustomerChatClient.hardCleanup();
    }
    return null;
  } else if (action.type === ACTION_CREATE) {
    const { isReadyToStartChat, conversationAttributes, startOptions } = action;
    const parentNode = document.getElementById("kustomer-chat-entry");

    if (!isReadyToStartChat) {
      return null;
    }

    if (!kustomerChatClient) {
      const newKustomerChatClient = new KustomerChatClient({
        parentNode,
        conversationAttributes,
        startOptions,
      });
      return newKustomerChatClient;
    } else if (
      !!kustomerChatClient &&
      !isEqual(kustomerChatClient.startOptions, startOptions)
    ) {
      // We have to completely refresh our existing Kustomer chat
      // instance if we want new startOptions to work.
      kustomerChatClient.hardCleanup();
      const newKustomerChatClient = new KustomerChatClient({
        parentNode,
        conversationAttributes,
        startOptions,
      });
      return newKustomerChatClient;
    } else if (
      !!kustomerChatClient &&
      !isEqual(
        kustomerChatClient.conversationAttributes,
        conversationAttributes
      )
    ) {
      // Update conversation attributes on existing client.
      kustomerChatClient.updateConversationAttributes(conversationAttributes);
    }
    return kustomerChatClient;
  }
};

const useKustomerChatClient = () => {
  const [kustomerChatClient, setKustomerChatClient] = useReducer(
    kustomerChatClientReducer,
    null
  );
  const [cancellationChatStarted, setCancellationChatStarted] = useState(false);

  const { user = null, isAuthenticated = null } = useContext(AuthContext);
  const { isFeatureOn, settings } = useContext(AdminContext);
  const chatOnline = useGeneralChatOnline();
  const { pathname } = useRouter();

  // hide Kustomer Chat icon if we're on special pages or if its after hours
  const isCancelPage = pathname.includes("/cancel");
  const isQuiz = pathname.includes("/quiz");
  const isCheckout =
    pathname.includes("/subscribe") || pathname.includes("/give-subscription");
  const isWithinChatHours = settings && settings["WITHIN_CHAT_HOURS"];
  const hideChatIcon = useMemo(() => {
    return (
      isCancelPage || isQuiz || !isWithinChatHours || isCheckout || !chatOnline
    );
  }, [isCancelPage, isCheckout, isQuiz, isWithinChatHours, chatOnline]);
  const showBackupChatIcon = useMemo(() => {
    return cancellationChatStarted && hideChatIcon;
  }, [cancellationChatStarted, hideChatIcon]);

  const startOptions = useMemo(() => {
    return {
      hideChatIcon,
      hideHistory: !isWithinChatHours,
      hideNewConversationButtonOnEnd: !isWithinChatHours,
    };
  }, [hideChatIcon, isWithinChatHours]);

  const isReadyToStartChat = useMemo(() => {
    const isChatAllowed = !!isAuthenticated && isFeatureOn("chat-enabled");
    return isChatAllowed && !!settings && !!user;
  }, [isAuthenticated, isFeatureOn, settings, user]);

  const conversationAttributes = useMemo(() => {
    return createConversationAttributes(isCancelPage, settings, user);
  }, [isCancelPage, settings, user]);

  const createCancellationChatConversation = (
    conversationOptions,
    conversationAttributes
  ) => {
    window.Kustomer?.createConversation(conversationOptions, (res, error) => {
      if (error) {
        Sentry.captureException(
          "Unable to start retention chat with KUSTOMER_RETENTION_ASSISANT",
          error
        );
        // If the KUSTOMER_RETENTION_ASSISANT has been accidentally deleted
        // within Kustomer, let's try to start a retention chat without it.
        delete conversationOptions.assistantId;
        window.Kustomer?.createConversation(
          conversationOptions,
          (res, error) => {
            if (error) {
              Sentry.captureException("Unable to start retention chat", error);
            } else {
              const { conversationId } = res;
              window.Kustomer.describeConversation({
                conversationId,
                customAttributes: conversationAttributes.values(),
              });
              setCancellationChatStarted(true);
            }
          }
        );
      } else {
        const { conversationId } = res;
        window.Kustomer.describeConversation({
          conversationId,
          customAttributes: conversationAttributes.values(),
        });
        setCancellationChatStarted(true);
      }
    });
  };

  const openAndSendCancellationMessage = useCallback(
    ({ message, selectedCategoryStr }) => {
      // openAndSendCancellationMessage should always create a cancellation
      // chat
      const isCancelPage = true;
      const conversationAttributes = createConversationAttributes(
        isCancelPage,
        settings,
        user,
        selectedCategoryStr
      );
      
      try {
        if (!selectedCategoryStr) {
          Sentry.captureException(new Error("No selectedCategoryStr"), {
            tags: {
              section: "kustomerChatClient",
            },
          });
        }
      } catch (err) {
        // Do nothing
      }

      const conversationOptions = {
        message,
        assistantId: KUSTOMER_RETENTION_ASSISANT,
      };
      if (
        process.env.NODE_ENV === "development" ||
        settings["KUSTOMER_USE_DEV_CHAT_QUEUE"]
      ) {
        // Don't send conversations to actual retention queue
        // while testing.
        delete conversationOptions.assistantId;
      }
      createCancellationChatConversation(
        conversationOptions,
        conversationAttributes
      );
    },
    [settings, user]
  );

  // Logout Kustomer if not authenticated.
  useEffect(() => {
    if (isAuthenticated === false) {
      try {
        logout();
        setKustomerChatClient({ type: ACTION_DELETE });
      } catch (err) {
        console.error(err);
      }
    }
  }, [isAuthenticated]);

  // Create kustomerChatClient
  useEffect(() => {
    setKustomerChatClient({
      type: ACTION_CREATE,
      isReadyToStartChat,
      conversationAttributes,
      startOptions,
    });
  }, [isReadyToStartChat, conversationAttributes, startOptions]);

  // Start kustomerChatClient
  useEffect(() => {
    if (!kustomerChatClient) {
      return;
    }
    kustomerChatClient.start();
    return () => {
      kustomerChatClient.cleanup();
    };
  }, [kustomerChatClient]);

  return {
    kustomerChatClient,
    openAndSendCancellationMessage,
    showBackupChatIcon,
    isReadyToStartChat,
  };
};

export default useKustomerChatClient;
