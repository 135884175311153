import { get } from "lodash";
import { facebookPixelTrackSubscribe } from "utils/facebook/facebookPixelTrackEvent";
import * as amplitude from "@amplitude/analytics-browser";
/* eslint @typescript-eslint/naming-convention: 0 */ // --> OFF

/**
 * Return product params for invoice object after checkout.
 */
const getProductParamsFromInvoice = (invoice) => {
  try {
    const items = invoice.items.map((item) => {
      if (item.type === "subscription") {
        return {
          id: item.object.productId,
          name: item.object.clubName,
        };
      }
      if (item.type === "gift") {
        return {
          id: item.object.productId,
        };
      }
    });

    const transaction_id = invoice.id;
    const value = invoice.order_value || invoice.orderValue;
    const coupon = invoice.coupon ? invoice.coupon.code : "";
    const zip_code = invoice.shippingAddress?.postalCode;

    return {
      value,
      items,
      transaction_id,
      content_ids: items.map(({ id }) => id),
      coupon,
      zip_code,
    };
  } catch (e) {
    console.error("Error assembling product params from invoice");
    console.error(e);
    console.error(invoice);
  }
  return null;
};

function submitAgeDetails(age) {
  if (window.gtag) {
    window.gtag("event", "age_start", {
      age: age,
    });
  } else {
    console.warn("Unable to send gtag age_start event");
  }
}

/**
 * @param {Object} args
 * @param {String} [args.estimatedLTV]
 * @param {Object} args.invoice
 * @param {String} args.url
 */
function subscriptionPurchased({ estimatedLTV, invoice, url }) {
  facebookPixelTrackSubscribe({
    estimatedLTV,
    invoice,
    url,
  });

  const productParams = getProductParamsFromInvoice(invoice);

  if (!productParams) return;

  if (window.pintrk) {
    window.pintrk("track", "Checkout", {
      product_name: "subscription",
      product_id: productParams.content_ids[0],
      value: productParams.value,
      currency: "USD",
    });
  } else {
    console.warn("Unable to send pintrk checout event");
  }

  if (window.gtag) {
    window.gtag("event", "conversion", {
      send_to: get(
        window,
        "_SETTINGS.ANALYTICS.ADWORDS2_SUBSCRIPTION_CONVERSION"
      ),
      value: productParams.value,
      currency: "USD",
      transaction_id: productParams.transaction_id,
      estimated_ltv: estimatedLTV,
    });
  } else {
    console.warn("Unable to send gtag conversion event");
  }
  if (window.snaptr) {
    window.snaptr("track", "PURCHASE", {
      currency: "USD",
      price: productParams.value,
      item_category: "subscription",
      item_ids: [productParams.content_ids[0]],
      transaction_id: productParams.transaction_id,
    });
  } else {
    console.warn("Unable to send snap pixel purchase event");
  }
}

function giftSubscriptionPurchased(invoice) {
  const productParams = getProductParamsFromInvoice(invoice);

  if (!productParams) return;

  if (window.pintrk) {
    window.pintrk("track", "Checkout", {
      product_name: "gift_subscription",
      product_id: productParams.content_ids[0],
      value: productParams.value,
      currency: "USD",
    });
  } else {
    console.warn("Unable to send pintrk checkout event");
  }

  if (window.gtag) {
    window.gtag("event", "gift_conversion", {
      send_to: get(window, "_SETTINGS.ANALYTICS.ADWORDS_GIFT_SUB_CONVERSION"),
      value: productParams.value,
      currency: "USD",
      transaction_id: productParams.transaction_id,
    });
  } else {
    console.warn("Unable to send gtag gift_conversion event");
  }
  if (window.snaptr) {
    window.snaptr("track", "PURCHASE", {
      currency: "USD",
      price: productParams.value,
      item_category: "gift_subscription",
      item_ids: [productParams.content_ids[0]],
      transaction_id: productParams.transaction_id,
    });
  } else {
    console.warn("Unable to send snap pixel purchase event");
  }
}

let authenticated = null;

function userAuthenticated(account) {
  if (typeof window === "undefined") return;
  if (authenticated === account.email) return;
}

function giftSubscriptionStarted() {
  if (window.gtag) {
    window.gtag("event", "start_giftsub");
  } else {
    console.warn("Unable to send gtag start_giftsub event");
  }
}

function submitGiftSubscriptionDetails() {
  if (window.gtag) {
    window.gtag("event", "submit_giftsubdetails");
  } else {
    console.warn("Unable to send gtag submit_giftsubdetails event");
  }
}

/**
 * Tracks a page view in Amplitude.
 *
 * @see pages/_app.js useEffect for global usage
 * @see https://amplitude.github.io/Amplitude-JavaScript/#amplitudeclientlogevent
 *
 * @param {String} url
 * @param {String} pageName
 */
function trackAmplitudePageView(url, pageName) {
  if (amplitude) {
    amplitude?.track("Loaded a page", {
      "event source": "literati-next-kids",
      url: window.location.href,
      "page name": pageName ?? document?.title,
      path: url ?? window.location.pathname,
    });
  }
}

/**
 * Tracks a click event in Amplitude.
 *
 * @see https://amplitude.github.io/Amplitude-JavaScript/#amplitudeclientlogevent
 *
 * @param {String} label
 * @param {Any} [value]
 * @param {String} [url]
 */
function trackAmplitudeClickEvent(label, value, url) {
  if (amplitude) {
    amplitude?.track("Clicked", {
      "event source": "literati-next-kids",
      path: url ?? window.location.pathname,
      url: window.location.href,
      label,
      value,
    });
  }
}

/**
 * @param {Object} properties
 * @param {"add"|"set"} action
 */
function setAmplitudeUserProperties(properties, action = "add") {
  if (amplitude && properties) {
    const identifyEvent = new amplitude.Identify();

    for (const [key, val] of Object.entries(properties)) {
      identifyEvent[action]?.(key, val);
    }

    amplitude.identify(identifyEvent);
  }
}

/**
 * Records the UTM parameters for the current URL
 */
export const setAmplitudeUTMParameters = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const keys = urlParams.keys();

  const utmParams = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];

  // Check if there are any parameters
  if (queryString && amplitude) {
    // Create the identify event
    const identifyEvent = new amplitude.Identify();

    // Loop through the params for the UTM parameters
    for (const key of keys) {
      // If the parameter is a UTM then add it to the identifyEvent
      if (utmParams.includes(key)) {
        identifyEvent.set(key, urlParams.get(key));
      }

      // Check for promo codes
      if ("extCouponCode" === key) {
        identifyEvent.set("coupon_code", urlParams.get(key));
      }

      if ("extAffiliateCode" === key) {
        identifyEvent.set("affiliate_code", urlParams.get(key));
      }
    }

    // Save the Identify Event
    amplitude.identify(identifyEvent);
  }
};

/**
 * Associates a user's authentication userId from backend with their
 * Amplitude user data.
 *
 * For use when a user becomes authenticated / we have a valid
 * userId to set for them.
 *
 * @note - Any previous activity that was performed by this user
 * before they were authenticated will become merged with this userId.
 *
 * @param {string} userId
 * @see https://developers.amplitude.com/docs/javascript#setting-custom-user-id
 */
function setAmplitudeUserId(userId) {
  if (amplitude && userId) {
    amplitude.setUserId(userId);
  }
}

/**
 * Any future activity after the user logs out is still associated with the last known
 * userId on this device, since we do not call `amplitude.getInstance().regenerateDeviceId();`
 *
 * @see https://developers.amplitude.com/docs/javascript#log-out-and-anonymous-users
 */
function setAmplitudeUserLoggedOut() {
  if (amplitude) {
    amplitude.setUserId(null);
  }
}

import * as bookShuffleAnalytics from "analytics/bookShuffleAnalytics";
import * as cancelAnalytics from "analytics/cancelAnalytics";

export default {
  ...bookShuffleAnalytics,
  ...cancelAnalytics,
  submitAgeDetails,
  subscriptionPurchased,
  giftSubscriptionPurchased,
  userAuthenticated,
  giftSubscriptionStarted,
  submitGiftSubscriptionDetails,
  trackAmplitudePageView,
  trackAmplitudeClickEvent,
  setAmplitudeUserProperties,
  setAmplitudeUTMParameters,
  setAmplitudeUserId,
  setAmplitudeUserLoggedOut,
};
