import { useState } from "react";
import useSWR from "swr";

/** Endpoint returns a raw boolean */
type ChatData = boolean;

/**
 * Checks if there are currently chat agents online for a given queue.
 * Pings django backend every 10 seconds for updates.
 * Returns True or False
 */
const chatQueueHookFactory = (queue: "retention" | "general") => () => {
  const [chatQueueOnline, setChatQueueOnline] = useState<ChatData>();
  const { data } = useSWR<ChatData, any>(
    `/api/customer-support/chat/${queue}_chat_online/`,
    {
      refreshInterval: 10000,
    }
  );
  if (data !== chatQueueOnline) {
    setChatQueueOnline(data);
  }

  return chatQueueOnline;
};

export const useRetentionChatOnline = chatQueueHookFactory("retention");
export const useGeneralChatOnline = chatQueueHookFactory("general");
