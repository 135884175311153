import { useToasts } from "react-toast-notifications";
import { formatToast } from "../index";
import { useCallback } from "react";
import { GENERIC_ERROR_MESSAGE } from "constants/forms";
import { isEmpty } from "lodash";

/** These are the arguments that callback that `useResponseParser` returns expects */
export interface UseResponseParserCallbackArgs<R> {
  res: R;
  onSuccess?: (res: R) => Promise<void> | void;
  onError?: (res: R) => Promise<void> | void;
  successMessage?: string;
  errorMessage?: string;
  toastOnError?: boolean;
  toastOnSuccess?: boolean;
}

type AnyRequestFn = (...args: any[]) => any;

/**
 * Utility type to the create all of the arg types that the returned `useResponseParser`
 * callback would expect, given a function that makes a request and returns a `Response`.
 */
export type MakeArgsFromRequestFn<RequestFn extends AnyRequestFn> =
  UseResponseParserCallbackArgs<Awaited<ReturnType<RequestFn>>>;

/**
 * Utility type to the create the handlers arg types that the returned `useResponseParser`
 * callback would expect, given a function that makes a request and returns a `Response`.
 */
export type MakeHandlerArgsFromRequestFn<RequestFn extends AnyRequestFn> = Pick<
  MakeArgsFromRequestFn<RequestFn>,
  "onError" | "onSuccess"
>;

const useResponseParser = () => {
  const { addToast } = useToasts();
  return useCallback(
    async <Response>({
      res,
      onSuccess,
      onError,
      successMessage,
      errorMessage,
      toastOnError = true,
      toastOnSuccess = true,
    }: UseResponseParserCallbackArgs<Response>) => {
      const fieldErrors = (res as any)?.error?.fieldErrors;
      const areFieldErrors = fieldErrors && !isEmpty(fieldErrors);
      if ((res as any)?.error === undefined) {
        if (successMessage && toastOnSuccess) {
          addToast(
            ...formatToast(successMessage, {
              appearance: "success",
            })
          );
        }
        // in the case of purchase, we want to route the user out before we update session
        onSuccess && (await onSuccess(res));
      } else {
        if (areFieldErrors && toastOnError) {
          addToast(
            ...formatToast(errorMessage || GENERIC_ERROR_MESSAGE, {
              appearance: "error",
            })
          );
        }
        onError && (await onError(res));
      }
      return res;
    },
    [addToast, formatToast]
  );
};

export default useResponseParser;
