import dynamic from "next/dynamic";
import { memo } from "react";
import cx from "classnames";

import {
  IconName as DLSIconName,
  IconMap as DLSIconMap,
  Icon as DLSIcon,
} from "@literati/public-ui-shared-react";

export const ICON_MAP = {
  apple: dynamic(() => import("./apple")),
  "arrow-right": dynamic(() => import("./arrow-right")),
  calendar: dynamic(() => import("./calendar")),
  "chat-bubble": dynamic(() => import("./chat-bubble")),
  checkmark: dynamic(() => import("./checkmark")),
  "checkmark-thin": dynamic(() => import("./checkmark-thin")),
  "chevron-circle-left-outline": dynamic(
    () => import("./chevron-circle-left-outline")
  ),
  "chevron-circle-right": dynamic(() => import("./chevron-circle-right")),
  "chevron-circle-right-outline": dynamic(
    () => import("./chevron-circle-right-outline")
  ),
  "chevron-down-wide": dynamic(() => import("./chevron-down-wide")),
  "chevron-right": dynamic(() => import("./chevron-right")),
  "circle-solid": dynamic(() => import("./circle-solid")),
  "diamond-solid": dynamic(() => import("./diamond-solid")),
  facebook: dynamic(() => import("./facebook-login")),
  google: dynamic(() => import("./google")),
  "help-outline": dynamic(() => import("./help-outline")),
  "hexagon-solid": dynamic(() => import("./hexagon-solid")),
  "holding-hands": dynamic(() => import("./holding-hands")),
  loading: dynamic(() => import("./loading")),
  "rating-emotion-1": dynamic(() => import("./rating-emotion-1")),
  "rating-emotion-2": dynamic(() => import("./rating-emotion-2")),
  "rating-emotion-3": dynamic(() => import("./rating-emotion-3")),
  "rating-emotion-4": dynamic(() => import("./rating-emotion-4")),
  "rating-emotion-5": dynamic(() => import("./rating-emotion-5")),
  "rectangle-solid": dynamic(() => import("./rectangle-solid")),
  "square-solid": dynamic(() => import("./square-solid")),
  "star-solid": dynamic(() => import("./star-solid")),
  "thin-arrow-left": dynamic(() => import("./thin-arrow-left")),
  "thin-arrow-right": dynamic(() => import("./thin-arrow-right")),
  times: dynamic(() => import("./times")),
  "triangle-solid": dynamic(() => import("./triangle-solid")),
  "user-circle": dynamic(() => import("./user-circle")),
  profile: dynamic(() => import("./profile")),
  box: dynamic(() => import("./box")),
  "frog-rating-1": dynamic(() => import("./frog-rating-1")),
  "frog-rating-2": dynamic(() => import("./frog-rating-2")),
  "frog-rating-3": dynamic(() => import("./frog-rating-3")),
  "frog-rating-4": dynamic(() => import("./frog-rating-4")),
  "frog-rating-5": dynamic(() => import("./frog-rating-5")),
  delivered: dynamic(() => import("./delivered")),
  curation: dynamic(() => import("./curation")),
  shipping: dynamic(() => import("./shipping")),
  "box-shipment": dynamic(() => import("./box-shipment")),
  lightning: dynamic(() => import("./lightning")),
  watch: dynamic(() => import("./watch")),
  print: dynamic(() => import("./print")),
  gift: dynamic(() => import("./gift")),
  lost: dynamic(() => import("./lost")),
  rainbow: dynamic(() => import("./rainbow")),
  "book-cover": dynamic(() => import("./book-cover")),
  "graduation-cap": dynamic(() => import("./graduation-cap")),
  card: dynamic(() => import("./card")),
  billing: dynamic(() => import("./billing")),
  "checkmark-circle-outline": dynamic(
    () => import("./checkmark-circle-outline")
  ),
  alert: dynamic(() => import("./alert")),
  logout: dynamic(() => import("./logout")),
  stars: dynamic(() => import("./stars")),
  "quiz-checkmark": dynamic(() => import("./quiz-checkmark")),
  "quiz-chevron-down": dynamic(() => import("./quiz-chevron-down")),
  "quiz-star": dynamic(() => import("./quiz-star")),
};

export type IconName = keyof typeof ICON_MAP;

export function isLegacyIcon(key: string): key is IconName {
  return key in ICON_MAP;
}

export function isDLSIcon(key: string): key is DLSIconName {
  return key in DLSIconMap;
}

export type IconProps = {
  name: IconName | DLSIconName;
  size: string;
  fill?: string;
  id?: string;
  title?: string;
  className?: string;
};

const Icon = memo(({ ...props }: IconProps) => {
  const styles = {
    color: "inherit",
    height: props.size,
    maxWidth: props.size,
    fill: props.fill || "currentColor",
    flexShrink: 0,
  };
  const customProps = {
    styles,
    ...props,
    className: cx("inline", props.className),
  };
  if (isDLSIcon(props.name)) {
    const name = props.name;
    return <DLSIcon className={props.className} name={name} />;
  } else if (isLegacyIcon(props.name)) {
    const IconByName = ICON_MAP[props.name] as React.FunctionComponent<any>;
    return <IconByName {...customProps} />;
  }
  return null;
});

export default Icon;
