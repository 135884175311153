import cx from "classnames";
import PropTypes from "prop-types";
import Link from "next/link";
import Icon from "components/atoms/Icon";
import colors from "./colors";
import StyledButton from "./styles";
import { throttle } from "utils";

const Button = (props) => {
  const {
    children,
    onClick,
    className,
    dataAnalytics,
    dataTestId,
    disabled,
    loading,
    label,
    level,
    link,
    linkExternal,
    type,
    color,
    style,
    customColors,
    target,
    throttleDelay,
    noThrottle,
    form,
  } = props;

  const handleClick = noThrottle
    ? onClick
    : onClick && throttle(onClick, throttleDelay, true);

  const unstyled = level === "unstyled";

  const {
    default: colorDefault,
    darker: colorDarker,
    lighter: colorLighter,
    labelColor,
  } = customColors || colors[color] || colors["teal"];

  let allClassNames = unstyled
    ? cx("unstyled-link cursor-pointer", className)
    : cx(
        "styled-btn px-8 relative",
        {
          disabled,
          loading,
          [level]: level,
        },
        className
      );

  if (!className?.includes("block")) {
    allClassNames += " inline-block";
  }

  const attributes = {
    className: allClassNames,
    onClick: handleClick,
    "data-analytics": dataAnalytics,
    "data-testid": dataTestId,
    disabled: disabled || loading,
    style,
    target,
  };

  const content = unstyled ? (
    label || children
  ) : (
    <>
      {label || children}
      {loading && (
        <div className="loading-container">
          <Icon size="1.25rem" name="loading" />
        </div>
      )}
    </>
  );

  const styleProps = {
    colorDefault,
    colorDarker,
    colorLighter,
    labelColor: props.labelColor || labelColor,
    loading,
    level,
  };

  return link ? (
    linkExternal ? (
      <a href={link} target={target}>
        <StyledButton role={"link"} {...attributes} $styleProps={styleProps}>
          {content}
        </StyledButton>
      </a>
    ) : (
      <Link href={link} passHref>
        <StyledButton role={"link"} {...attributes} $styleProps={styleProps}>
          {content}
        </StyledButton>
      </Link>
    )
  ) : (
    <StyledButton
      type={type || "button"}
      {...attributes}
      $styleProps={styleProps}
      form={form}
    >
      {content}
    </StyledButton>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["button", "submit"]),
  label: PropTypes.any,
  link: PropTypes.string,
  linkExternal: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  dataAnalytics: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  level: PropTypes.oneOf(["primary", "secondary", "tertiary", "unstyled"]),
  style: PropTypes.object,
  color: PropTypes.oneOf(Object.keys(colors)),
  customColors: PropTypes.shape({
    default: PropTypes.string.isRequired,
    darker: PropTypes.string,
    lighter: PropTypes.string,
  }),
  labelColor: PropTypes.string,
  target: PropTypes.string,
  throttleDelay: PropTypes.number,
  noThrottle: PropTypes.bool,
  form: PropTypes.string,
};

Button.defaultProps = {
  color: "textGray",
  level: "primary",
  className: "",
  throttleDelay: 1000,
};

export default Button;
