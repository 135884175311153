import {
  DefaultAPIResponse,
  ParsedErrorResponse,
  isAPIError,
  requestJSON,
} from "lib/api/base";
import { deepMapKeys, mapKeysSnakeCase } from "utils";
import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";
import { FREQUENCY_OPTIONS } from "globalConfig";
import { GetServerSidePropsContext } from "next";
import {
  SnakeToCamelCaseNested,
  ActiveBoxShipmentDetailSchema,
  CompletedBoxShipmentDetailsSchema,
  UnlimitedSubscriptionPlan,
  GiftSubscriptionPlan,
  PrepaidSubscriptionPlan,
  TryBeforeYouBuySubscriptionPlan,
  ClubSchema,
  PastBoxesResponse,
  SubscriptionSchema,
  CouponCodeSchema,
  VoucherSchema,
  BoxPreviewSchema,
  ActiveBoxShipmentSchema,
  SubscriptionWithShipmentDetailsSchema,
  CancellationRefundResponseSchema,
} from "@literati/kids-api";
import { SubscriptionDetailsSchemaCamelCase } from "contexts/SubscriptionsProvider";

const BASE_URL = "/api/subscriptions";

export type BoxShipment = SnakeToCamelCaseNested<
  ActiveBoxShipmentDetailSchema | CompletedBoxShipmentDetailsSchema
>;

export type Subscription = SnakeToCamelCaseNested<SubscriptionSchema> & {
  estimatedGrade?: any;
  shippingAddress?: Partial<AddressInformation>;
  readingLevelGrade?: number;
};

export interface GetSubscriptionPayload {
  id: number;
  token?: string | null | undefined;
}

export async function getSubscription(
  { id, token = null }: GetSubscriptionPayload,
  ctx?: GetServerSidePropsContext
) {
  const url = `${BASE_URL}/${id}` as const;
  const data = await requestJSON(url, { token }, "get", { ctx });
  return data;
}

export async function getSubscriptions(ctx?: GetServerSidePropsContext) {
  const url = `${BASE_URL}/` as const;
  const res = await requestJSON(url, { all: "true", overview: "true" }, "get", {
    ctx,
  });
  if (isAPIError(res)) {
    return res;
  } else {
    return res.subscriptions;
  }
}

export async function getSubscriptionDelivery(
  { id }: { id: number },
  ctx?: GetServerSidePropsContext
) {
  const url = `${BASE_URL}/${id}/delivery` as const;
  const data = await requestJSON(url, undefined, "get", {
    ctx,
  });
  return data;
}

export interface GetDeliveryPreviewPayload {
  id: number;
}

export async function getDeliveryPreview(
  { id }: GetDeliveryPreviewPayload,
  ctx?: GetServerSidePropsContext
) {
  return await requestJSON(
    `${BASE_URL}/${id}/preview-delivery`,
    undefined,
    "get",
    {
      ctx,
    }
  );
}

export interface GetDeliveryWithPreviewPayload {
  id: number;
}

export async function getDeliveryWithPreview(
  { id }: GetDeliveryWithPreviewPayload,
  ctx?: GetServerSidePropsContext
) {
  const [delivery, preview] = await Promise.all([
    getSubscriptionDelivery({ id }, ctx),
    getDeliveryPreview({ id }, ctx),
  ]);
  FREQUENCY_OPTIONS.forEach((o) => {
    const k = camelCase(o.value) as keyof typeof preview;
    Object.assign(preview[k] || {}, o);
  });
  return { ...delivery, preview };
}

export interface AttemptCancellationPayload {
  id: number;
}

export async function attemptCancellation(
  { id }: AttemptCancellationPayload,
  cancelCategory: string
) {
  return await requestJSON(
    `${BASE_URL}/${id}/attempt-cancellation`,
    {
      category: cancelCategory,
    },
    "post"
  );
}

export async function confirmCancellation(
  { id }: { id: number },
  cancelCategory: string,
  nextShipmentCancellable: boolean,
  cancelReason?: string
) {
  return await requestJSON(
    `${BASE_URL}/${id}/cancel`,
    {
      category: cancelCategory,
      reason: cancelReason,
      // assume next box should be canceled if eligible
      cancel_shipments: nextShipmentCancellable,
    },
    "post"
  );
}
type DeliveryTimelineMonth = {
  everyOtherIncluded: boolean;
  included: boolean;
  month: number;
  name: string;
  quarterlyIncluded: boolean;
  year: number;
};

export interface UpdateDeliveryTimelinePayload {
  subId: number;
  deliveryTimeline?: DeliveryTimelineMonth[];
  skipDuration?: [number, number, number];
}

export async function updateDeliveryTimeline(
  { subId, deliveryTimeline, skipDuration }: UpdateDeliveryTimelinePayload,
  ctx?: GetServerSidePropsContext
) {
  return await requestJSON(
    `${BASE_URL}/${subId}/edit_delivery`,
    deepMapKeys(
      { skipDuration, deliveryTimeline, skip_policy: "" },
      snakeCase
    ) as object,
    "post",
    { ctx }
  );
}

export interface DelayNextBoxPayload {
  subscriptionId: number;
  weeks: number;
}

export type DelayNextBoxResponseCamelCase =
  SnakeToCamelCaseNested<SubscriptionWithShipmentDetailsSchema>;

export async function delayNextBox(
  { subscriptionId, weeks }: DelayNextBoxPayload,
  ctx?: GetServerSidePropsContext
) {
  return await requestJSON(
    `${BASE_URL}/${subscriptionId}/delay-next-box`,
    { delayed_weeks: weeks },
    "post",
    { ctx }
  );
}

export type PreviewDelayedBoxResponseCamelCase = {
  shippingWindowEnd: string;
  shippingWindowStart: string;
  weeks: number;
}[];

export async function previewDelayedBox(
  subscriptionId: number,
  ctx?: GetServerSidePropsContext
): Promise<ParsedErrorResponse | PreviewDelayedBoxResponseCamelCase> {
  return await requestJSON(
    `${BASE_URL}/${subscriptionId}/preview-delayed-box`,
    undefined,
    "post",
    { ctx }
  );
}

// past boxes - where's my stuff

export type PastBoxesResponseCamelCase =
  SnakeToCamelCaseNested<PastBoxesResponse>;

export interface GetPastBoxesPayload {
  subId: number;
  limit?: number;
  page?: number;
}

export async function getPastBoxes(
  { subId, limit, page }: GetPastBoxesPayload,
  ctx?: GetServerSidePropsContext
): Promise<PastBoxesResponseCamelCase> {
  const paginationParams = limit && page ? `?limit=${limit}&page=${page}` : "";
  return await requestJSON(
    `${BASE_URL}/${subId}/past-boxes${paginationParams}`,
    undefined,
    "get",
    { ctx }
  );
}

export async function getActiveBoxDetails(
  { subId, token }: { subId: number; token?: string },
  ctx?: GetServerSidePropsContext
) {
  return await requestJSON(
    `${BASE_URL}/${subId}/active-box-details`,
    { token },
    "get",
    { ctx }
  );
}

export interface GetActiveReturnsPayload {
  subId: number;
}

export async function getActiveReturns(
  { subId }: GetActiveReturnsPayload,
  ctx?: GetServerSidePropsContext
) {
  return await requestJSON(
    `${BASE_URL}/${subId}/active-return-details`,
    undefined,
    "get",
    { ctx }
  );
}

export interface UpdateChildInfoPayload {
  firstName?: string;
  month: number;
  year: number;
  preserveReadingLevel?: boolean;
  subId: number;
}

export async function updateChildInfo({
  month,
  year,
  firstName,
  preserveReadingLevel,
  subId,
}: UpdateChildInfoPayload) {
  return await requestJSON(
    `${BASE_URL}/${subId}/update-child`,
    {
      child: {
        month,
        year,
        first_name: firstName,
        preserve_reading_level: preserveReadingLevel,
      },
    },
    "post"
  );
}

interface ClubPayload {
  club: string;
  subId: number;
}

export async function updateClub({ club, subId }: ClubPayload) {
  return await requestJSON(
    `${BASE_URL}/${subId}/update-club`,
    { club },
    "post"
  );
}

/** @todo remove once `/clubs` endpoint is typed */
export type ClubSchemaCamelCase = SnakeToCamelCaseNested<ClubSchema>;

/** @todo remove once `/clubs` endpoint is typed */
export async function getClubs(
  ctx?: GetServerSidePropsContext
): Promise<ClubSchemaCamelCase[]> {
  return await requestJSON(`${BASE_URL}/clubs`, null, "get", { ctx });
}

export interface GetCompleteSubscriptionPayload {
  subId: number;
}

export async function getCompleteSubscription(
  { subId }: GetCompleteSubscriptionPayload,
  ctx?: GetServerSidePropsContext
) {
  const [subRes, deliveryRes] = await Promise.all([
    getSubscription({ id: subId }, ctx),
    getDeliveryWithPreview({ id: subId }, ctx),
  ]);
  if (isAPIError(subRes)) {
    return subRes;
  }
  const { subscription, ...rest } = subRes;
  return { ...subscription, ...rest, delivery: deliveryRes };
}

export type CompleteSubscriptionRes = Awaited<
  ReturnType<typeof getCompleteSubscription>
>;
export type CompleteSubscription = Exclude<
  CompleteSubscriptionRes,
  ParsedErrorResponse
>;

export interface AddressInformation {
  name: string;
  street1: string;
  street2: string;
  city: string;
  postalCode: string; // string to allow for input of zip+4 codes
  state: string;
  phoneNumber?: string;
  country: string;
}

export async function validateAddress({
  name,
  street1,
  street2,
  city,
  postalCode, // string to allow for input of zip+4 codes
  state,
  country,
}: AddressInformation) {
  return await requestJSON(
    `${BASE_URL}/validate-address`,
    {
      postal_address: {
        name,
        street1,
        street2: street2 || "",
        city,
        postal_code: postalCode,
        state,
        country,
      },
    },
    "post"
  );
}

export interface RefundForIneligibleAddressPayload {
  subscriptionId: number;
  address: AddressInformation;
}

export type RefundForIneligibleAddressRes =
  | SnakeToCamelCaseNested<CancellationRefundResponseSchema>
  | ParsedErrorResponse;

export async function refundForIneligibleAddress({
  subscriptionId,
  address: { name, street1, street2, city, postalCode, state, country },
}: RefundForIneligibleAddressPayload): Promise<RefundForIneligibleAddressRes> {
  return await requestJSON(
    `${BASE_URL}/${subscriptionId}/cancel-and-refund`,
    {
      postal_address: {
        name,
        street1,
        street2: street2 || "",
        city,
        postal_code: postalCode,
        state,
        country,
      },
    },
    "post"
  );
}

export interface SaveAddressPayload {
  address: {
    name: string;
    street1: string;
    street2: string;
    city: string;
    postalCode: string; // string to allow for input of zip+4 codes
    state: string;
    country: string;
  };
  subId: number;
}

/** @todo - remove once the API endpoint `/api/subscriptions/<SUB_ID>/save-address` is typed */
export interface SaveAddressResponse {
  warnings: unknown[];
  subscription: SubscriptionDetailsSchemaCamelCase;
  nextShippingWindow: [string, string];
  nextShipmentCancellable: boolean;
  nextShipmentRefundable: boolean;
}

export async function saveAddress({
  address: { name, street1, street2, city, postalCode, state, country = "US" },
  subId,
}: SaveAddressPayload): Promise<SaveAddressResponse> {
  return await requestJSON(
    `${BASE_URL}/${subId}/save-address`,
    {
      postal_address: {
        name,
        street1,
        street2,
        city,
        postal_code: postalCode,
        state,
        country,
      },
    },
    "post"
  );
}

export interface GetExtendedReturnPreviewPayload {
  boxId: number;
  offerId?: number;
}

export async function getExtendReturnPreview(
  { boxId, offerId }: GetExtendedReturnPreviewPayload,
  ctx?: GetServerSidePropsContext
) {
  return await requestJSON(
    `/api/box-shipments/${boxId}/extend-return-preview`,
    mapKeysSnakeCase({ offerId }),
    "get",
    {
      ctx,
    }
  );
}

export interface ExtendReturnWindowPayload {
  days: number;
  boxId: string;
  offerId: string;
}

export async function extendReturnWindow({
  days,
  boxId,
  offerId,
}: ExtendReturnWindowPayload) {
  return await requestJSON(
    `/api/box-shipments/${boxId}/extend-return`,
    {
      days,
      offer_id: offerId,
    },
    "post"
  );
}

/** handles both child and club */
export interface UpdateDetailsDetailsPayload {
  club: string;
  child: {
    firstName: string;
    month: number;
    year: number;
  };
}

export async function updateDetails(
  subId: number,
  details: UpdateDetailsDetailsPayload
) {
  return await requestJSON(
    `${BASE_URL}/${subId}/update-details`,
    deepMapKeys(details, snakeCase) as object,
    "post"
  );
}

export interface GetOrderSummaryPayload {
  boxId?: number;
  subscriptionId?: number;
  plan?: string;
  coupon?: string;
}

/** Keeping these here to avoid changes to the checkout provider **/
export async function getOrderSummary({
  boxId,
  subscriptionId,
  plan,
  coupon,
}: GetOrderSummaryPayload) {
  const subscriptionParam = subscriptionId && `subscription=${subscriptionId}`;
  const boxShipmentParam = boxId && `box_shipment=${boxId}`;
  const planParam = plan && `plan=${plan}`;
  const couponParam = coupon && `coupon=${coupon}`;
  const query = [subscriptionParam, boxShipmentParam, planParam, couponParam]
    .filter(Boolean)
    .join("&");
  const url = `/api/checkout/order-summary`;
  const endpoint = query.length ? url + `?${query}` : url;
  return await requestJSON(endpoint, undefined, "get");
}

/** @todo remove once API endpoint for `/api/subscriptions-checkout/subscription-plans` is typed */
export type SubscriptionPlan =
  | TryBeforeYouBuySubscriptionPlan
  | PrepaidSubscriptionPlan
  | GiftSubscriptionPlan
  | UnlimitedSubscriptionPlan;

/** @todo remove once API endpoint for `/api/subscriptions-checkout/subscription-plans` is typed */
export type SubscriptionPlanCamelCase =
  SnakeToCamelCaseNested<SubscriptionPlan>;

/** @todo remove `as` once API endpoint for `/api/subscriptions-checkout/subscription-plans` is typed */
export async function getSubscriptionPlans(
  ctx?: GetServerSidePropsContext
): Promise<SubscriptionPlanCamelCase[]> {
  return await requestJSON(
    `/api/subscriptions-checkout/subscription-plans`,
    undefined,
    "get",
    {
      ctx,
    }
  );
}

export interface ReactivateSubscriptionPayload {
  subscriptionId: number;
  plan: string;
}

/**
 * @todo - remove explicit return type here once the `/api/subscriptions/<SUBSCRIPTION_ID>/reactivate`
 * API endpoint is typed
 */
export async function reactivateSubscription({
  subscriptionId,
  plan,
}: ReactivateSubscriptionPayload) {
  return await requestJSON(
    `${BASE_URL}/${subscriptionId}/reactivate`,
    {
      plan,
    },
    "post"
  );
}

export interface GetAffiliatesPayload {
  subId: number;
  page?: number;
}

export async function getAffiliates(
  { subId, page }: GetAffiliatesPayload,
  ctx?: GetServerSidePropsContext
) {
  return await requestJSON(
    `${BASE_URL}/${subId}/get-affiliates${page ? `?page=${page}` : ""}`,
    undefined,
    "get",
    {
      ctx,
    }
  );
}

export interface AddAffiliatePayload {
  subId: number;
  affiliate: {
    recipientEmail: string;
    recipientName: string;
    recipientMetadata: {
      [index: string]: unknown;
    };
    affiliateType: string;
  };
}

export async function addAffiliate({ subId, affiliate }: AddAffiliatePayload) {
  return await requestJSON(
    `${BASE_URL}/${subId}/add-affiliate`,
    deepMapKeys(affiliate, snakeCase) as object,
    "post"
  );
}

export interface RemoveAffiliatePayload {
  subId: number;
  affiliateId: string; // ? maybe
}

export async function removeAffiliate({
  subId,
  affiliateId,
}: RemoveAffiliatePayload) {
  return await requestJSON(
    `${BASE_URL}/${subId}/remove-affiliate/${affiliateId}`,
    undefined,
    "delete"
  );
}

export interface GetRetentionOffersPayload {
  subId: number;
}

/**
 * @todo - delete this type once this endpoint is documented in `@literati/kids-api`
 * @see - `RetentionOfferSchema` in `literatibooks`
 */
export interface Offer {
  id: number;
  offerType: string;
  customerDescription?: string | null;
  couponCode?: SnakeToCamelCaseNested<CouponCodeSchema> | null;
  voucher?: SnakeToCamelCaseNested<VoucherSchema> | null;
  itemCount?: number | null;
  ltvBased: boolean;
}

export async function getRetentionOffers(
  { subId }: GetRetentionOffersPayload,
  ctx?: GetServerSidePropsContext
): Promise<Offer[] | ParsedErrorResponse> {
  const offers = await requestJSON(
    `${BASE_URL}/${subId}/offers`,
    undefined,
    "get",
    {
      ctx,
    }
  );
  return offers?.error ? [] : offers;
}

export interface RedeemRetentionOfferPayload {
  subId: number;
  offerId: unknown;
}

export async function redeemRetentionOffer({
  subId,
  offerId,
}: RedeemRetentionOfferPayload) {
  return await requestJSON(
    `${BASE_URL}/${subId}/offer/${offerId}/redeem`,
    undefined,
    "post"
  );
}

export interface GetFulfillmentGroupPreviewPayload {
  subId: number;
}

export async function getFulfillmentGroupPreview(
  { subId }: GetFulfillmentGroupPreviewPayload,
  ctx: GetServerSidePropsContext
) {
  return await requestJSON(
    `${BASE_URL}/${subId}/preview-fulfillment-changes`,
    undefined,
    "get",
    {
      ctx,
    }
  );
}

export interface UpdateFulfillmentGroupPayload {
  subId: number;
  payload: {
    fulfillmentGroup: number;
  };
}

export async function updateFulfillmentGroup({
  subId,
  payload,
}: UpdateFulfillmentGroupPayload) {
  return await requestJSON(
    `${BASE_URL}/${subId}/update-fulfillment-group`,
    deepMapKeys(payload, snakeCase) as object,
    "post"
  );
}

export async function getBoxDetails(
  { boxId, token }: { boxId: number; token?: string },
  ctx?: GetServerSidePropsContext
) {
  const url = `/api/box-shipments/${boxId}/details` as const;
  const data = await requestJSON(url, { token }, "get", { ctx });
  return data;
}

export async function getBoxWithTrackingHistory(
  { boxId, token }: { boxId: number; token?: string },
  ctx?: GetServerSidePropsContext
) {
  const url = `/api/box-shipments/${boxId}/tracking-history` as const;
  const data = await requestJSON(url, { token }, "get", { ctx });
  return data;
}

export type TrackingResult = Exclude<
  Awaited<ReturnType<typeof getBoxWithTrackingHistory>>,
  { error: object }
>["trackingHistory"];

export async function getLatestBoxInvoice(
  { boxId, token }: { boxId: number; token: string },
  ctx?: GetServerSidePropsContext
) {
  const url = `/api/checkout/box-shipments/${boxId}/latest-invoice` as const;
  const data = await requestJSON(url, { token }, "get", { ctx });
  if (isAPIError(data)) {
    return data;
  }
  return data?.invoice;
}

export type ActiveBoxShipment = SnakeToCamelCaseNested<ActiveBoxShipmentSchema>;
export async function getCurrentBoxes(
  { token }: { token?: string },
  ctx?: GetServerSidePropsContext
) {
  const url = `/api/box-shipments/current` as const;
  const data = await requestJSON(url, { token }, "get", { ctx });
  return data;
}

export type BoxPreview = SnakeToCamelCaseNested<BoxPreviewSchema>;
export async function getUpcomingBoxes(
  { token }: { token?: string },
  ctx?: GetServerSidePropsContext
): Promise<DefaultAPIResponse<BoxPreviewSchema[]>> {
  const url = `/api/box-shipments/upcoming` as const;
  const data = await requestJSON(url, { token }, "get", { ctx });
  return data;
}
