import { ScopedStorageContext } from "contexts/ScopedStorage";
import { useMemo, useEffect, useState, useContext } from "react";

export const StorageType = {
  LOCAL: "localStorage",
  SESSION: "sessionStorage",
} as const;

export type FakeOrRealWindowStorage = Pick<
  Storage,
  "getItem" | "setItem" | "removeItem"
>;

const fakeStorage: FakeOrRealWindowStorage = {
  getItem: () => null,
  setItem: () => undefined,
  removeItem: () => undefined,
};

export const makeStorageInterface = <K>({
  storage,
  scopePrefix = "",
}: {
  storage?: FakeOrRealWindowStorage;
  scopePrefix?: string;
}) => {
  const getItem = <V>(key: K): V | null => {
    try {
      const valueFromStorage = storage?.getItem(`${scopePrefix}${key}`);
      if (!valueFromStorage) return null;
      return JSON.parse(valueFromStorage) as V;
    } catch (e) {
      console.error("useWindowStorage: ", e);
      return null;
    }
  };

  const setItem = <V>(key: K, value: V) => {
    try {
      const jsonValue = JSON.stringify(value);
      storage?.setItem(`${scopePrefix}${key}`, jsonValue);
    } catch (e) {
      console.error("useWindowStorage: ", e);
    }
  };

  const removeItem = (key: K) => {
    try {
      return storage?.removeItem(`${scopePrefix}${key}`);
    } catch (e) {
      console.error("useWindowStorage: ", e);
    }
  };

  return { getItem, removeItem, setItem };
};

export type StorageGetItem = <V>(key: string) => V | null;
export type StorageRemoveItem = (key: string) => void;
export type StorageSetItem = (<V>(key: string, value: V) => void) | undefined;

/**
 * Lightweight storage wrapper to handle JSON logic and error handling.
 *
 * This hook also allows us to provide isolated `localStorage` and
 * `sessionStorage` instances to each of our automated tests.
 *
 * @note - when run server-side, the `storage` object returned will be a
 * mock storage object that does nothing. For this reason, it's helpful
 * to check `isClient` before making any calls and/or run in a `useEffect`,
 * since `useEffect`s are only run client-side.
 */
export default function useWindowStorage<K extends string = string>(
  type: "localStorage" | "sessionStorage" = StorageType.LOCAL
) {
  const { scopePrefix } = useContext(ScopedStorageContext);
  // this hook runs once server-side and then once again client-side
  const [isClient, storage] = useMemo(
    () => {
      const isClient = typeof window === "object";
      return [isClient, isClient ? window[type] : fakeStorage];
    },
    // should ignore changes to `type`
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const storageReady = isClient;

  const { getItem, removeItem, setItem } = useMemo(
    () =>
      makeStorageInterface<K>({
        storage,
        scopePrefix,
      }),
    [storage, scopePrefix]
  );

  return [storageReady, { getItem, removeItem, setItem }] as const;
}

/**
 * @deprecated - prefer to use `useWindowStorage` hook instead, since
 * it does not require an unnecessary render cycle for storage to be ready client-side.
 *
 * Lightweight storage wrapper to handle JSON logic and error handling.
 *
 * @note - Window is undefined on initial renders, so you will need to
 *         wait for storage to populate before attempting to interact
 *         with local or session storage.
 *
 * @todo - (REFACTOR) - this can be probably refactored to work on first render client-side by
 *         by providing an initialization function to `useState` rather than by updating state
 *         in a `useEffect`, which is also a re-render performance concern.
 */
export function useWindowStorageDeprecated(
  type: "localStorage" | "sessionStorage" = StorageType.LOCAL
) {
  const { scopePrefix } = useContext(ScopedStorageContext);
  const [storage, setStorage] = useState<FakeOrRealWindowStorage | undefined>();
  const [storageReady, setStorageReady] = useState(false);

  useEffect(() => {
    const _storage = typeof window === "undefined" ? fakeStorage : window[type];
    setStorage(_storage);
    setStorageReady(!!_storage);
  }, []);

  const { getItem, removeItem, setItem } = makeStorageInterface({
    storage,
    scopePrefix,
  });

  return [storageReady, { getItem, removeItem, setItem }] as const;
}
