import isEmpty from "lodash/isEmpty";
import { ParsedErrorResponse, requestJSON } from "lib/api/base";
import { CheckoutSession } from "./checkout";
import { mapKeysSnakeCase } from "utils";
import * as checkout from "./checkout";
import { GetServerSidePropsContext } from "next";
import { CodeDetailsSchema, SnakeToCamelCaseNested } from "@literati/kids-api";

const BASE_URL = "/api/gift-checkout";

export interface SubscriptionPlan {
  slug: string;
  name: string;
  amount: number;
  boxCount: number;
}

export async function getGiftPlans(
  ctx?: GetServerSidePropsContext
): Promise<SubscriptionPlan[]> {
  return await requestJSON(`${BASE_URL}/subscription-plans`, undefined, "get", {
    ctx,
  });
}

export async function selectGiftPlan(plan: string) {
  return await requestJSON(`${BASE_URL}/select-plan`, { plan: plan }, "post");
}

export interface GiftDetails {
  childName: string;
  note: string;
  email: string;
  notifyDate: string;
  senderFirstName: string;
  senderLastName: string;
}

export async function setGiftDetails(details: GiftDetails, partial = false) {
  const queryString = partial ? "?partial=true" : "";
  return await requestJSON(
    `${BASE_URL}/set-details${queryString}`,
    {
      recipient_info: mapKeysSnakeCase(details),
    },
    "post"
  );
}

export async function getSuggestedClubs(
  ctx?: GetServerSidePropsContext
): Promise<checkout.ClubRecommendationResponse> {
  return await requestJSON(`${BASE_URL}/suggested-clubs`, undefined, "get", {
    ctx,
  });
}

export async function addPaymentMethod(
  paymentType: string,
  paymentToken: string,
  authToken?: string
) {
  let endpoint = `${BASE_URL}/add-payment-method`;
  if (authToken) {
    endpoint = `${endpoint}?token=${authToken}`;
  }
  return checkout.addPaymentMethod(paymentType, paymentToken, { endpoint });
}

export async function addGiftPromoCode(code: string) {
  return await requestJSON(`${BASE_URL}/add-promo`, { code }, "post");
}

export async function purchaseGift() {
  return await requestJSON(`${BASE_URL}/purchase-gift`, {}, "post");
}

export async function getGiftOrderSummary(ctx?: GetServerSidePropsContext) {
  const endpoint = `${BASE_URL}/order-summary`;
  return checkout.getOrderSummary(ctx, { endpoint });
}

export interface GiftCheckoutSession extends CheckoutSession {
  selectedPlan?: string;
  recipientInfo?: GiftDetails;
  paymentMethod?: any;
}

export async function getGiftCheckoutSession(
  ctx?: GetServerSidePropsContext
): Promise<GiftCheckoutSession> {
  const result = await requestJSON(`${BASE_URL}/session`, undefined, "get", {
    ctx,
  });
  const session: GiftCheckoutSession = {};
  const { defaultPaymentMethod, auth, paymentMethod } = result;
  const currentItem = result?.items[0] || {};
  session.selectedPlan = currentItem.plan || null;
  session.recipientInfo = currentItem.recipientInfo || null;
  session.accountInfo = !isEmpty(auth) ? auth : {};
  session.defaultPaymentMethod = !isEmpty(defaultPaymentMethod)
    ? result.defaultPaymentMethod
    : null;
  session.paymentMethod = !isEmpty(paymentMethod) ? result.paymentMethod : null;
  return session;
}

interface GiftResponse {
  code: string;
  amount: number;
  box_count: number;
  plan: {
    name: string;
    slug: string;
  };
  gift_type: string;
  sender_name: string;
  message: string;
  recipient_name: string;
  recipient_email: string;
}

export async function getGift(
  { giftCode }: any,
  ctx?: GetServerSidePropsContext
): Promise<GiftResponse> {
  return await requestJSON(
    `${BASE_URL}/get-gift/${giftCode}`,
    undefined,
    "get",
    { ctx }
  );
}

export type GiftOptions = {
  can_claim_as_new_subscription: boolean;
  can_redeem_as_credit: boolean;
  gift_code: string;
};

export type GiftOptionsCamelCase = SnakeToCamelCaseNested<GiftOptions>;

export async function getGiftOptions(
  { giftCode }: any,
  ctx?: GetServerSidePropsContext
): Promise<GiftOptionsCamelCase | ParsedErrorResponse> {
  return await requestJSON(
    `${BASE_URL}/gift-options/${giftCode}`,
    undefined,
    "get",
    { ctx }
  );
}

export async function claimGiftCredit({
  giftCode,
  allowRedeemed = false,
}: {
  giftCode: string;
  allowRedeemed?: boolean;
}) {
  return await requestJSON(
    `${BASE_URL}/redeem-gift/${giftCode}${
      allowRedeemed ? "?allow_redeemed=1" : ""
    }`,
    undefined,
    "post"
  );
}

export async function claimGiftSub({
  giftCode,
}: {
  giftCode: string;
}): Promise<SnakeToCamelCaseNested<CodeDetailsSchema> | ParsedErrorResponse> {
  return await requestJSON(
    `${BASE_URL}/claim-gift/${giftCode}`,
    undefined,
    "post"
  );
}

export async function claimVoucher({ code, subscriptionId = null }: any) {
  return await requestJSON(
    `${BASE_URL}/claim-gift/${code}`,
    {
      subscription_id: subscriptionId,
    },
    "post"
  );
}

export type CodeDetailsSchemaCamelCase =
  SnakeToCamelCaseNested<CodeDetailsSchema>;

export type GetCodeDetailsResponse =
  | CodeDetailsSchemaCamelCase
  | ParsedErrorResponse;

export const getCodeDetails = async (
  { code }: { code: string },
  ctx?: GetServerSidePropsContext
): Promise<GetCodeDetailsResponse> => {
  return await requestJSON(
    `/api/checkout/code/${code}/details`,
    undefined,
    "get",
    { ctx }
  );
};
