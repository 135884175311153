const LogoutModalIllus = () => {
  return (
    <svg
      width="78"
      height="79"
      viewBox="0 0 78 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={"mx-auto"}
    >
      <g clipPath="url(#clip0_3932_17662)">
        <path
          d="M12.6748 26.3967C12.6748 11.8578 24.4609 0.0716553 38.9998 0.0716553C53.5387 0.0716553 65.3248 11.8578 65.3248 26.3967V72.2217C65.3248 75.4525 62.7057 78.0717 59.4748 78.0717H18.5248C15.2939 78.0717 12.6748 75.4525 12.6748 72.2217V26.3967Z"
          fill="#B1E4F3"
        />
        <path
          d="M51.1963 10.3658C51.7253 9.67848 52.821 9.97207 52.9354 10.8318L53.801 17.3329C53.8492 17.6954 54.0962 18.0003 54.4406 18.1229L60.6198 20.3212C61.4369 20.6119 61.4963 21.7447 60.714 22.1192L54.7985 24.9514C54.4687 25.1092 54.255 25.4383 54.2449 25.8038L54.0637 32.3599C54.0397 33.2268 52.9807 33.6333 52.3828 33.0051L47.8612 28.2543C47.6092 27.9894 47.2301 27.8879 46.8794 27.9912L40.5882 29.8448C39.7563 30.0899 39.0424 29.2083 39.4552 28.4455L42.5762 22.6772C42.7502 22.3556 42.7297 21.9638 42.523 21.6621L38.8161 16.2516C38.3259 15.5362 38.9437 14.5848 39.7967 14.7416L46.2472 15.9274C46.6068 15.9935 46.9731 15.8529 47.1961 15.5632L51.1963 10.3658Z"
          fill="#FFAA42"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.17312 33.5847C17.3751 23.8622 29.1538 22.8917 34.0848 23.6309C34.6173 23.7107 34.9843 24.2071 34.9045 24.7396C34.8246 25.2722 34.3282 25.6392 33.7957 25.5593C29.312 24.8872 18.3015 25.7881 10.6636 34.8421C10.3164 35.2536 9.70125 35.3058 9.28967 34.9586C8.87808 34.6114 8.8259 33.9963 9.17312 33.5847Z"
          fill="#232323"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.7281 59.0036C29.8349 46.3317 38.9058 38.7555 43.3628 36.5204C43.8441 36.279 44.43 36.4735 44.6714 36.9549C44.9128 37.4362 44.7183 38.0221 44.2369 38.2635C40.1842 40.2959 31.7014 47.3729 30.6707 59.1733C30.6238 59.7097 30.151 60.1066 29.6145 60.0597C29.0781 60.0129 28.6812 59.54 28.7281 59.0036Z"
          fill="#232323"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.206 49.3376C18.5549 37.384 29.2776 32.4137 34.1612 31.4083C34.6886 31.2997 35.2042 31.6392 35.3128 32.1666C35.4214 32.694 35.0818 33.2096 34.5544 33.3182C30.1138 34.2324 20.0883 38.8728 16.0385 50.0043C15.8544 50.5103 15.295 50.7713 14.7889 50.5872C14.2829 50.4031 14.0219 49.8436 14.206 49.3376Z"
          fill="#232323"
        />
        <path
          d="M55.5751 42.9716L55.9105 43.8779C56.7005 46.0129 58.3838 47.6963 60.5188 48.4863L61.4251 48.8216L60.5188 49.157C58.3838 49.947 56.7005 51.6303 55.9105 53.7654L55.5751 54.6716L55.2397 53.7654C54.4497 51.6304 52.7664 49.947 50.6314 49.157L49.7251 48.8216L50.6314 48.4863C52.7664 47.6963 54.4497 46.0129 55.2397 43.8779L55.5751 42.9716Z"
          fill="#232323"
        />
        <path
          d="M38.0251 71.7342C38.0251 72.5419 37.3703 73.1967 36.5626 73.1967C35.7549 73.1967 35.1001 72.5419 35.1001 71.7342C35.1001 70.9265 35.7549 70.2717 36.5626 70.2717C37.3703 70.2717 38.0251 70.9265 38.0251 71.7342Z"
          fill="#232323"
        />
        <path
          d="M43.8752 66.8592C43.8752 67.6669 43.2204 68.3217 42.4127 68.3217C41.605 68.3217 40.9502 67.6669 40.9502 66.8592C40.9502 66.0515 41.605 65.3967 42.4127 65.3967C43.2204 65.3967 43.8752 66.0515 43.8752 66.8592Z"
          fill="#232323"
        />
        <ellipse
          cx="36.5626"
          cy="4.45914"
          rx="1.4625"
          ry="1.4625"
          fill="#232323"
        />
        <path
          d="M22.9125 61.4966L23.2118 62.3056C23.6069 63.3731 24.4485 64.2148 25.516 64.6098L26.325 64.9091L25.516 65.2085C24.4485 65.6035 23.6069 66.4452 23.2118 67.5127L22.9125 68.3216L22.6132 67.5127C22.2181 66.4452 21.3765 65.6035 20.309 65.2085L19.5 64.9091L20.309 64.6098C21.3765 64.2148 22.2181 63.3731 22.6132 62.3056L22.9125 61.4966Z"
          fill="#232323"
        />
        <path
          d="M27.7875 8.84665L28.0868 9.65562C28.4819 10.7231 29.3235 11.5648 30.391 11.9598L31.2 12.2591L30.391 12.5585C29.3235 12.9535 28.4819 13.7952 28.0868 14.8627L27.7875 15.6716L27.4882 14.8627C27.0931 13.7952 26.2515 12.9535 25.184 12.5585L24.375 12.2591L25.184 11.9598C26.2515 11.5648 27.0931 10.7231 27.4882 9.65562L27.7875 8.84665Z"
          fill="#232323"
        />
      </g>
      <defs>
        <clipPath id="clip0_3932_17662">
          <rect
            width="78"
            height="78"
            fill="white"
            transform="translate(0 0.0716553)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoutModalIllus;
