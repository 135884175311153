import { useEffect, useState } from "react";
import { NextRouter, useRouter } from "next/router";
import {
  Experiment,
  ExperimentConfig,
  ExperimentClient,
  Variant,
} from "@amplitude/experiment-js-client";
import * as amplitude from "@amplitude/analytics-browser";
import analytics from "analytics";
import useWindowStorage, {
  StorageGetItem,
  StorageSetItem,
} from "utils/hooks/useWindowStorage";

/**  @see https://www.docs.developers.amplitude.com/experiment/general/data-model/#variants */
export type AmplitudeExperimentVariant = Variant;

// We choose to manually expose clients to experiments for finer control of the experience.
export const initConfigDefault: ExperimentConfig = {
  automaticExposureTracking: false,
  fetchTimeoutMillis: 2000,
};

const logAmplitudeFailure = (e: string) => {
  console.warn(`Amplitude failed to initialize: ${e}`);
};

export const AmplitudeExperimentDummyClient = {
  fetch: () => logAmplitudeFailure("call to `fetch` does nothing"),
  variant: () => {
    logAmplitudeFailure("call to `variant` does nothing");
    return {};
  },
  exposure: () => logAmplitudeFailure("call to `exposure` does nothing"),
  all: () => logAmplitudeFailure("call to `all` does nothing"),
  getUser: () => logAmplitudeFailure("call to `getUser` does nothing"),
  setUser: () => logAmplitudeFailure("call to `setUser` does nothing"),
  getUserProvider: () =>
    logAmplitudeFailure("call to `getUserProvider` does nothing"),
  setUserProvider: () =>
    logAmplitudeFailure("call to `setUserProvider` does nothing"),
};

export type UserPropertyValue =
  | string
  | number
  | boolean
  | (string | number | boolean)[];
export type UserProperties = { [propertyName: string]: UserPropertyValue };

export function isUserPropertyValueType(value: unknown) {
  function isBaseType(v: unknown) {
    switch (typeof v) {
      case "string":
      case "number":
      case "boolean":
        return true;
      default:
        return false;
    }
  }

  if (isBaseType(value)) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.every((v) => isBaseType(v));
  }
}

/**
 * Returns user  properties to set on initial fetch. This allows us to leverage Amplitude's
 * audience filtering to prevent users who do not match url parameter constraints from being
 * assigned to configured experiments.
 *
 * In addition to setting user properties on initial fetch, we also make an `identify` call
 * on mount in _app.js. In practice this helps us catch all cases:
 *   1. User lands on page where we evaluate experiment with query parameters.
 *   2. User lands on different page, then navigates to experiment page.
 */
export const getUserProperties = (
  router: NextRouter,
  getItem: StorageGetItem,
  setItem: StorageSetItem
): UserProperties => {
  const queryTranslationMap: Record<string, string> = {
    extCouponCode: "coupon_code",
    extAffiliateCode: "affiliate_code",
  };

  const queryEntries = Object.entries(router.query);

  const userProperties: UserProperties = queryEntries.reduce(
    (acc, [key, value]) => {
      if (isUserPropertyValueType(value)) {
        const k = queryTranslationMap[key] ? queryTranslationMap[key] : key;
        return { ...acc, [k]: value };
      } else {
        return acc;
      }
    },
    {}
  );

  if (!userProperties.coupon_code) {
    userProperties.coupon_code = "NO_COUPON_APPLIED";
  }

  if (!userProperties.affiliate_code) {
    userProperties.affiliate_code = "NO_AFFILIATE_CODE_APPLIED";
  }

  // @note - allows `schoolbox-prepaid` and `annual-plan-credits-60` to run mutually exclusively
  const propertyKey = "initial_path" as const;
  const saved = getItem(propertyKey);
  const path = router.asPath;
  const isValidPath = ["/", "/schoolbox", "/subscribe", "/activation"].some(
    (p) => path.includes(p)
  );

  if (isValidPath) {
    if (typeof saved !== "string") {
      setItem?.(propertyKey, path);
      userProperties[propertyKey] = path;
    } else {
      userProperties[propertyKey] = saved;
    }
  }

  return userProperties;
};

export type AmplitudeExperimentClient =
  | typeof AmplitudeExperimentDummyClient
  | ExperimentClient
  | null;

export type GetAmplitudeAnalyticsFn = () => any;

const getAmplitudedAnalyticsDefault: GetAmplitudeAnalyticsFn = () => amplitude;

/**
 * Initializes the Amplitude Experiment client.
 *
 * @note We opinionatedly initialize with an Amplitude Analytics integration.
 *
 * @see https://www.docs.developers.amplitude.com/experiment/sdks/javascript-sdk/
 * @see https://www.docs.developers.amplitude.com/experiment/sdks/javascript-sdk/#integrations
 */
export default function useAmplitudeExperimentInit({
  deploymentKey,
  config = initConfigDefault,
  getAmplitudeAnalytics = getAmplitudedAnalyticsDefault,
}: {
  deploymentKey: string;
  /** @see https://www.docs.developers.amplitude.com/experiment/sdks/javascript-sdk/#configuration */
  config?: ExperimentConfig;
  /** Retrieve global instance (abstracted for easier testing) */
  getAmplitudeAnalytics?: GetAmplitudeAnalyticsFn;
}) {
  const [client, setClient] = useState<AmplitudeExperimentClient>(null);
  const [storageReady, { setItem }] = useWindowStorage("sessionStorage");
  const [_, { getItem: localStorageGetItem, setItem: localStorageSetItem }] =
    useWindowStorage("localStorage");
  const router = useRouter();

  async function initialize() {
    try {
      if (!deploymentKey) {
        console.error("Amplitude Experiment SDK deploymentKey is not defined");
      }

      const amplitudeAnalytics = getAmplitudeAnalytics();
      if (amplitudeAnalytics) {
        const ampClient = Experiment.initializeWithAmplitudeAnalytics(
          deploymentKey,
          config
        );

        const userProperties = getUserProperties(
          router,
          localStorageGetItem,
          localStorageSetItem
        );

        await ampClient.fetch?.({ user_properties: userProperties });

        analytics.setAmplitudeUserProperties(userProperties, "set");

        setClient(ampClient);
        console.info("Amplitude Experiment SDK was successfully initialized");
      } else {
        console.error(
          "Amplitude Analytics SDK failed to initialize: `getAmplitudeAnalytics` returned a falsy value. AmplitudeExperimentDummyClient will be returned instead."
        );
        setClient(AmplitudeExperimentDummyClient);
      }
    } catch (e) {
      console.error(
        "Error while initializing Amplitude Experiment Client. AmplitudeExperimentDummyClient will be returned instead. Original SDK Error:",
        e
      );
      setClient(AmplitudeExperimentDummyClient);
    }
  }

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (client && storageReady) {
      const experiments = client.all();
      setItem("experiments", experiments);
    }
  }, [client]);

  return {
    amplitudeExperimentClient: client,
    amplitudeExperimentClientInitialized: !!client,
  };
}
