import { useContext } from "react";
import { get } from "lodash";
import { AdminContext } from "contexts/AdminProvider";
import { AccountContext } from "contexts/AccountProvider";
import Head from "next/head";

const Snap = () => {
  const { email } = useContext(AccountContext);
  const { settings } = useContext(AdminContext);
  const snapPixelId = get(settings, "ANALYTICS.SNAP_PIXEL_ID");
  return !snapPixelId || !email ? null : (
    <Head>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
                    (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                        {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                        a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                        r.src=n;var u=t.getElementsByTagName(s)[0];
                        u.parentNode.insertBefore(r,u);})(window,document,
                        'https://sc-static.net/scevent.min.js');

                        snaptr('init', '${snapPixelId}', {
                        'user_email': '${email}'
                        });

                        snaptr('track', 'PAGE_VIEW');`,
        }}
        key="snap"
      />
    </Head>
  );
};

export default Snap;
