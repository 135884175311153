import { useRouter } from "next/router";
import useWindowStorage from "utils/hooks/useWindowStorage";

const isValidUtmValue = (value: unknown) =>
  value === "facebook" || value === "google";

export default function useUtmSocialVisitor() {
  const router = useRouter();
  const [_, { getItem, setItem }] = useWindowStorage("sessionStorage");

  const utmPropertyKeys = ["utm_initial_source", "utm_source"];
  const [utmInitialSource, utmSource] = utmPropertyKeys.map((key) => {
    let storageValue = getItem(key) || null;

    const queryParameterValue = router.query?.[key] || null;
    const cleanedQueryParameterValue = isValidUtmValue(queryParameterValue)
      ? queryParameterValue
      : null;

    if (!storageValue) {
      setItem(key, cleanedQueryParameterValue);
      storageValue = cleanedQueryParameterValue;
    }

    return {
      storageValue,
      queryParameterValue,
      isUtmSocialVisitor:
        isValidUtmValue(storageValue) || isValidUtmValue(queryParameterValue),
    };
  });

  const isUtmSocialVisitor =
    utmInitialSource.isUtmSocialVisitor || utmSource.isUtmSocialVisitor;

  return {
    isUtmSocialVisitor,
    utmInitialSource,
    utmSource,
  };
}
