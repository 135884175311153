import React, { ReactNode, createContext } from "react";
import useKustomerChatClient from "utils/hooks/useKustomerChatClient.js";
import { KUSTOMER_Z_INDEX } from "theme";
import styled from "styled-components";
import { cleanImageURL } from "@literati/public-ui-shared-react";

const StyledChatIcon = styled.div`
  width: 60px;
  height: 60px;
  background-size: contain;
  background-image: url(${cleanImageURL("https://clubs-media.literati-static.com/chat-widgets/kustomer-chat-icon.png")});
  position: fixed;
  right: 20px;
  bottom: 20px;
`;

export type OpenAndSendCancellationMessageFn = (payload: {
  message: string;
  selectedCategoryStr: string;
}) => void;

export interface KustomerContextType {
  openChat?: () => void;
  openAndSendCancellationMessage?: OpenAndSendCancellationMessageFn;
}

export const KustomerContext = createContext<KustomerContextType>({});

/**
 * We create our own chat icon if we have an active cancellation chat
 * in progress.
 *
 * In some circumatances (such as after business hours), the chat icon
 * should be hidden.
 *
 * But if someone is in the middle of a 24-hour eligible cancellation chat,
 * we want them to be able to open/close the chat modal.
 *
 * Without this CustomChatIcon, if someone closes the chat modal, they
 * will not be able to open it again if they navigate away from the cancellation
 * page.
 */
const CustomChatIcon = ({ openChat }: { openChat: () => void }) => {
  // One less than Kustomer's zIndex.
  // So that CustomChatIcon doesn't overlap Kustomer's
  // own open chat Modal.
  const zIndex = KUSTOMER_Z_INDEX - 1;

  return (
    <StyledChatIcon
      className="literati-custom-chat-icon"
      tabIndex={0}
      role="button"
      aria-label="Team Literati Chat Widget Icon"
      onClick={openChat}
      style={{ zIndex }}
    />
  );
};

export interface KustomerProviderProps {
  children?: ReactNode;
}

const KustomerProvider = ({ children }: KustomerProviderProps) => {
  const { openAndSendCancellationMessage, showBackupChatIcon } =
    useKustomerChatClient();

  const openChat = () => {
    window.Kustomer?.open();
  };

  const value = {
    openAndSendCancellationMessage,
    openChat,
  };

  return (
    <KustomerContext.Provider value={value}>
      {children}
      {showBackupChatIcon && <CustomChatIcon openChat={openChat} />}
    </KustomerContext.Provider>
  );
};

export default KustomerProvider;
