import { mapKeysSnakeCase } from "@literati/kids-api";
import { requestJSON, ParsedErrorResponse } from "lib/api/base";
import { NEXTJS_BASE_PATH, NEXT_PUBLIC_KIDS_URL } from "globalConfig";
import { GetServerSidePropsContext } from "next";

export interface SocialLoginToken {
  provider: string;
  token: string;
}

/** @todo replace once this endpoint is typed */
export interface Auth {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  isAuthenticated: boolean;
  user: {
    id: number;
    amplitudeId: string;
    email: string;
    isAuthenticated: boolean;
    canLogIn: boolean;
    firstName: string;
    lastName: string;
    phone: string;
    chatJwtToken: string;
    kustomerChatToken: string;
    isAffiliate: boolean;
    socialAccounts: unknown;
  };
}

export async function getAuth(ctx?: GetServerSidePropsContext) {
  return (await requestJSON("/api/whoami/", undefined, "GET", { ctx })) as
    | Auth
    | ParsedErrorResponse;
}

export async function loginWithToken(payload: any) {
  return await requestJSON(
    `${NEXT_PUBLIC_KIDS_URL}${NEXTJS_BASE_PATH}/api/login/`,
    payload,
    "POST"
  );
}

export async function linkProvider(payload: any) {
  return await requestJSON("/api/auth/link", payload, "POST");
}

export async function unlinkProvider(payload: any) {
  return await requestJSON("/api/auth/unlink", payload, "POST");
}

export async function logout() {
  return await requestJSON(
    `${NEXT_PUBLIC_KIDS_URL}${NEXTJS_BASE_PATH}/api/logout/`,
    null,
    "POST"
  );
}

export async function validatePassword(payload: any) {
  return await requestJSON("/api/auth/token", payload, "POST");
}

export type SetMailingListPayload = {
  mailingList: boolean;
  mailingListDefault?: boolean;
};

export async function setMailingList(
  { mailingList, mailingListDefault = true }: SetMailingListPayload,
  ctx?: GetServerSidePropsContext
) {
  return await requestJSON(
    "/api/auth/set-mailing-list",
    mapKeysSnakeCase({
      mailingList,
      mailingListDefault,
    }),
    "POST",
    { ctx }
  );
}
