import { ParsedErrorResponse, requestJSON } from "./base";
import { mapKeysSnakeCase } from "utils";
import { GetServerSidePropsContext } from "next";
import {
  SnakeToCamelCaseNested,
  UserProfileInputSchema,
  BookDetailSchema,
} from "@literati/kids-api";

const BASE_URL = "/api/account";

export interface DummyAccountInformation {
  firstName?: string;
  lastName?: string;
  email?: string;
  mailingList?: boolean;
  safeForSignup?: boolean;
  uid?: string;
  id?: string;
  amplitudeId?: string;
}

export type CreateDummyAccountResponse = {
  uid: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  is_teacher: boolean;
};
export type CreateDummyAccountResponseCamelCase =
  SnakeToCamelCaseNested<CreateDummyAccountResponse>;

export async function createDummyAccount({
  firstName,
  lastName,
  email,
  mailingList,
  safeForSignup,
  uid,
}: DummyAccountInformation) {
  return await requestJSON(
    `/api/auth/create-dummy-account`,
    {
      first_name: firstName,
      last_name: lastName,
      mailing_list: mailingList,
      email,
      safe_for_signup: safeForSignup,
      uid,
    },
    "post"
  );
}

export interface AccountInformation extends DummyAccountInformation {
  password: string;
}

export async function createAccount({
  firstName,
  lastName,
  email,
  mailingList,
  password,
  uid,
}: AccountInformation) {
  const baseArgs = {
    first_name: firstName,
    last_name: lastName,
    mailing_list: mailingList,
    email,
    password,
  };
  const args = uid ? { ...baseArgs, uid } : baseArgs;

  return await requestJSON(`/api/auth/create-account`, args, "post");
}

export async function getAuth(ctx?: GetServerSidePropsContext) {
  return await requestJSON("/api/whoami", undefined, "get", { ctx });
}

export async function login({ login, password }: any) {
  return await requestJSON(`/api/auth/login`, { login, password }, "post");
}

export type UserProfile = SnakeToCamelCaseNested<UserProfileInputSchema>;

export async function updateProfile(profile: UserProfile) {
  return await requestJSON(
    `${BASE_URL}/update-profile/`,
    {
      profile: mapKeysSnakeCase(profile),
    },
    "post"
  );
}

export async function getPaymentMethods(ctx?: GetServerSidePropsContext) {
  const res = await requestJSON(
    `${BASE_URL}/payment-methods/`,
    undefined,
    "get",
    {
      ctx,
    }
  );
  return res;
}

export interface PasswordChange {
  oldpassword: string;
  password1: string;
  password2: string;
}

export async function changePassword(payload: PasswordChange) {
  return await requestJSON("/api/auth/change-password/", payload, "post");
}

export async function getVouchers(ctx: GetServerSidePropsContext) {
  return await requestJSON(`${BASE_URL}/vouchers/`, null, "GET", { ctx });
}

export async function getReferrals(ctx: GetServerSidePropsContext) {
  return await requestJSON(`${BASE_URL}/referrals/`, null, "GET", { ctx });
}

export async function redeem({ code }: any) {
  return await requestJSON(
    `${BASE_URL}/redeem`,
    {
      code,
    },
    "POST"
  );
}

export async function getOrders(
  { page }: any,
  ctx?: GetServerSidePropsContext
) {
  return await requestJSON(
    `${BASE_URL}/orders?page=${page || 1}`,
    null,
    "GET",
    { ctx }
  );
}

interface RateBookPayload {
  bookId: number;
  payload: {
    boxShipment: number;
    feedback: object[];
  };
}

export async function rateBook(
  args: RateBookPayload,
  ctx?: GetServerSidePropsContext
) {
  const { bookId, payload } = args;
  return await requestJSON(
    `${BASE_URL}/books/${bookId}/rate`,
    mapKeysSnakeCase(payload),
    "POST",
    {
      ctx,
    }
  );
}

export async function requestAccountDeletion() {
  return await requestJSON(
    "/api/account/request-deletion/",
    undefined,
    "delete"
  );
}

interface GetBookRequest {
  bookId: number;
  ctx?: GetServerSidePropsContext;
}

export type BookDetails = SnakeToCamelCaseNested<BookDetailSchema>;

export async function getBook({
  bookId,
  ctx,
}: GetBookRequest): Promise<ParsedErrorResponse | BookDetails> {
  return await requestJSON(`${BASE_URL}/books/${bookId}`, null, "GET", { ctx });
}
