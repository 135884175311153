import { DefaultToast } from "react-toast-notifications";
import styled, { createGlobalStyle } from "styled-components";

/*
The messages are typically displayed below the header. However, some actions may provide feedback
at a scroll depth where the primary message isn't visible, so we provide a fixed position toast
message that hides if the primary is in the viewport. We could explore using a React portal as well.
 */

const StyledToast = styled(DefaultToast)`
  position: relative;
  div {
    max-width: 300px;

  .react-toast-notifications__toast__icon-wrapper {
      background-color: ${(props) => props.$levelColor};
    }
  .react-toast-notifications__toast__content {
      line-height: unset;
    }
  }
}
`;

const GlobalToastStyle = createGlobalStyle`
  .react-toast-notifications__container {
    z-index: 999999999999 !important;
  }
  
  .react-toast-notifications__toast__icon-wrapper {
    justify-content: center;
  }
  
  .react-toast-notifications__toast__content,
  .react-toast-notifications__toast__icon-wrapper,
  .react-toast-notifications__toast__dismiss-button {
    display: grid;
    align-items: center;
  }
`;

const Toast = ({ children, customId, hide, ...props }) => {
  const levelColor = {
    error: "#bb1653",
    warning: "#ffd04d",
    success: "#20d1cc",
    info: "#808083",
  }[props.appearance];

  return hide ? null : (
    <>
      <StyledToast {...props} $levelColor={levelColor}>
        {children}
      </StyledToast>
      <GlobalToastStyle />
    </>
  );
};

export default Toast;
