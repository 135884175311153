import styled from "styled-components";
import Modal from "@/atoms/account/Modal";
import Button from "@/atoms/Button";
import LogoutModalIllus from "./LogoutModalIllus";
import { useRouter } from "next/router";

const StyledLogoutModal = styled(Modal)`
  .modal-content-wrapper {
    padding: 4rem 1rem 1rem;
    text-align: center;
    width: 500px;
    max-width: 100%;

    ${({ theme }) => theme.media["sm"]} {
      padding: 3rem;
    }
  }
  .heading {
    font-size: 28px;
    color: #1e202e;
    margin-top: 1rem;
  }

  .subhhead {
    font-size: 18px;
    color: #646464;
  }
`;

const LogoutModal = (props) => {
  const { asPath, pathname } = useRouter();

  // logging out from /kids should keep users on the same page,
  // otherwise redirect to login page and return user to where they were after login
  const nextPath =
    pathname === "/" ? "/" : asPath ? `/login?next=${asPath}` : "/";

  return (
    <StyledLogoutModal {...props}>
      <LogoutModalIllus />
      <h2 className={"heading"}>Sign Out</h2>
      <div className={"subhhead"}>Are you sure you want to sign out?</div>
      <Button
        color={"teal"}
        className={"w-full mt-6"}
        onClick={() => props?.onLogout?.(nextPath)}
      >
        Sign Out
      </Button>
    </StyledLogoutModal>
  );
};

export default LogoutModal;
