import styled, { css } from "styled-components";

const StyledButton = styled.button`
  ${({ $styleProps }) => css`
    --colorDefault: ${$styleProps.colorDefault};
    --colorDarker: ${$styleProps.colorDarker || $styleProps.colorDefault};
    --colorLighter: ${$styleProps.colorLighter || $styleProps.colorDefault};
    --labelColor: ${($styleProps.level === "primary"
      ? $styleProps.labelColor
      : $styleProps.colorDefault) || "#fff"};
    ${$styleProps.loading ? `color: transparent !important;` : ""}
  `}

  text-align: center;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease;
  border-radius: 45px;
  white-space: nowrap;
  font-size: 1rem;
  height: 3.25rem;

  &:focus {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

  &.sm {
    height: 2.5rem;
    font-size: 0.875rem;
  }

  &.primary {
    color: var(--labelColor);
    background-color: var(--colorDefault);
    &:hover,
    &:focus {
      background-color: var(--colorLighter);
    }
    &:active {
      background-color: var(--colorDarker);
    }
  }

  &.secondary,
  &.tertiary {
    color: var(--colorDefault);
    border: 1px solid var(--colorDefault) !important;

    &:hover,
    &:focus {
      background: #fff;
      color: var(--colorDarker);
    }
    &:active {
      color: var(--colorDarker);
    }
  }

  &.disabled {
    background-color: #ededed !important;
    border-color: #ededed !important;
    color: #b6b6b6 !important;
  }

  &:disabled {
    cursor: default;
  }

  &.tertiary {
    border-radius: 9px;
  }

  .loading-container {
    position: absolute;
    color: var(--labelColor) !important;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: none;
  }

  &.loading {
    .label-container {
      visibility: hidden;
    }
    .loading-container {
      display: block;
    }
  }
`;

export default StyledButton;
