import { createContext, useContext, useEffect, useMemo, useRef } from "react";
import useReducerFromObject from "utils/hooks/useReducerFromObject";
import * as api from "lib/api/checkout";
import * as gifts from "lib/api/gifts";
import * as accountAPI from "lib/api/account";
import { useRouter } from "next/router";
import analytics from "analytics";
import { useToasts } from "react-toast-notifications";
import { formatToast } from "utils";
import { AccountContext } from "contexts/AccountProvider";
import cloneDeep from "lodash/cloneDeep";
import * as Sentry from "@sentry/node";
import { facebookPixelTrackPurchase } from "utils/facebook/facebookPixelTrackEvent";
import { ReactNode } from "react";
import { GiftDetails } from "lib/api/gifts";
import { CaptureContext } from "@sentry/types";
import { GENERIC_ERROR_MESSAGE } from "constants/forms";
import {
  SubscriptionPlanSlug,
  isSubscriptionPlanSlug,
} from "components/PlanSelection/planSelectionTypes";

export interface CheckoutProviderState {
  suggestedClubs?: api.ClubRecommendationResponse;
  orderSummary?: api.OrderSummary;
  session?: api.CheckoutSession;
  getSuggestedClubs?: () => Promise<unknown>;
  getSuggestedGiftClubs?: () => Promise<unknown>;
  updateSelectedClub?: (selectedClub: string) => Promise<unknown>;
  addPaymentMethod?: (
    paymentType: string,
    paymentToken: string
  ) => Promise<unknown>;
  addPromoCode?: (code: string) => Promise<unknown>;
  getPromos?: (codes: string[]) => Promise<unknown>;
  removePromoCode?: () => Promise<unknown>;
  updateChildInfo?: (
    personalization: api.ChildInfo,
    toastOnError?: boolean
  ) => Promise<unknown>;
  getOrderSummary?: () => Promise<unknown>;
  purchaseSubscription?: (
    successCallback?: (
      res: api.PurchaseSubscriptionResponse
    ) => Promise<unknown>,
    options?: {
      skipAddress?: boolean;
      estimatedLTV?: string;
      suppressAutoRoute?: boolean;
    }
  ) => Promise<unknown>;
  validateShippingAddress?: (
    address: api.AddressInformation
  ) => Promise<unknown>;
  saveShippingAddress?: (
    address: api.AddressInformation,
    itemId?: string
  ) => Promise<unknown>;
  createDummyAccount?: (
    accountInformation: accountAPI.DummyAccountInformation,
    toastOnError?: boolean
  ) => Promise<unknown>;
  getGiftOrderSummary?: () => Promise<unknown>;
  addGiftPaymentMethod?: (
    paymentType: string,
    paymentToken: string,
    authToken: string
  ) => Promise<unknown>;
  selectGiftPlan?: (plan: string) => Promise<unknown>;
  setGiftDetails?: (
    details: GiftDetails,
    partial?: boolean
  ) => Promise<unknown>;
  addGiftPromoCode?: (code: string) => Promise<unknown>;
  getCheckoutSession?: () => Promise<unknown>;
  getGiftCheckoutSession?: () => Promise<unknown>;
  purchaseGift?: () => Promise<unknown>;
  updateSelectedPlan?: ({
    plan,
    subscription,
  }: {
    plan: SubscriptionPlanSlug;
    subscription?: unknown;
  }) => Promise<unknown>;
  updateExistingPlan?: ({
    plan,
    subscription,
  }: {
    plan: string;
    subscription: unknown;
  }) => Promise<unknown>;
  updateDeliverySchedule?: ({
    schedule,
  }: {
    schedule: string;
  }) => Promise<unknown>;
}

export type CheckoutProviderStateKey = keyof CheckoutProviderState;

export type CheckoutProviderAction = {
  type: ReducersKey;
} & Partial<CheckoutProviderState>; // map of any new state to add to the existing state

const DEFAULT_STATE: CheckoutProviderState = {};

/** Creates a reducer that updates the indicated `stateKey` with new state from the `action` */
const setTopLevelKey =
  <S extends CheckoutProviderStateKey>(stateKey: S) =>
  (state: CheckoutProviderState, action: CheckoutProviderAction) => {
    const newState: CheckoutProviderState = {};
    if (action[stateKey]) {
      newState[stateKey] = action[stateKey];
    }
    return Object.assign({}, state, newState);
  };

const REDUCERS = {
  setSuggestedClubs: setTopLevelKey("suggestedClubs"),
  setOrderSummary: setTopLevelKey("orderSummary"),
  setCheckoutSession: setTopLevelKey("session"),
};

export type ReducersKey = keyof typeof REDUCERS;

export const CheckoutContext = createContext<CheckoutProviderState>({});

export interface CheckoutProviderProps {
  children: ReactNode;
}

// Get experiment data from local storage to be saved with purchase
export const getExperiments = (): object => {
  let experiments;
  try {
    experiments = JSON.parse(sessionStorage.getItem("experiments") || "") || {};
  } catch (error) {
    // Guard against invalid JSON being present in sessionStorage so we don't block purchase
    experiments = {};
    Sentry.captureException(
      "Unable to retrieve experiment data from session",
      error as CaptureContext
    );
  }
  return experiments;
};

export const CheckoutProvider = ({ children }: CheckoutProviderProps) => {
  const [state, dispatch] = useReducerFromObject<
    ReducersKey,
    CheckoutProviderState
  >(REDUCERS, DEFAULT_STATE);
  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    if ("extSubscriptionPlan" in query) {
      const plan = String(query.extSubscriptionPlan);
      if (isSubscriptionPlanSlug(plan)) {
        updateSelectedPlan({ plan });
      }
    }
  }, [query]);

  /** @todo - remove this `as any` once `AccountContext` is typed */
  const { refreshPaymentMethods } = useContext(AccountContext) as any;

  const { addToast } = useToasts();
  const parseResponse = async ({
    res,
    onSuccess,
    toastOnError,
  }: {
    res: unknown;
    onSuccess?: (() => unknown | Promise<unknown>) | void;
    toastOnError?: boolean;
  }) => {
    if (!(res as any)?.error) {
      // in the case of purchase, we want to route the user out before we update session
      onSuccess && (await onSuccess());
    } else {
      if (toastOnError && !(res as any)?.error?.fieldErrors) {
        addToast(
          ...formatToast(
            (res as any)?.error?.message || GENERIC_ERROR_MESSAGE,
            {
              appearance: "error",
            }
            /** @todo - remove `as` here once `formatToast` is typed */
          )
        );
      }
    }
    return res;
  };

  const setCheckoutSession = (session: api.CheckoutSession) =>
    dispatch({ type: "setCheckoutSession", session });

  const getCheckoutSession = async () => {
    const res = await api.getCheckoutSession();
    const onSuccess = setCheckoutSession(res as api.CheckoutSession);
    return parseResponse({ res, onSuccess });
  };

  const getGiftCheckoutSession = async () => {
    const res = await gifts.getGiftCheckoutSession();
    const onSuccess = setCheckoutSession(res);
    return parseResponse({ res, onSuccess });
  };

  const setSuggestedClubs = (suggestedClubs: api.ClubRecommendationResponse) =>
    dispatch({ type: "setSuggestedClubs", suggestedClubs });

  const getSuggestedClubs = async () => {
    const res = await api.getSuggestedClubs();
    const onSuccess = () => setSuggestedClubs(res);
    return parseResponse({ res, onSuccess });
  };

  const getSuggestedGiftClubs = async () => {
    const res = await gifts.getSuggestedClubs();
    const onSuccess = () => setSuggestedClubs(res);
    return parseResponse({ res, onSuccess });
  };

  const setGiftDetails = async (details: GiftDetails, partial = false) => {
    const res = await gifts.setGiftDetails(details, partial);
    const onSuccess = async () => {
      analytics.submitGiftSubscriptionDetails();
      partial && (await getSuggestedGiftClubs());
      return getGiftCheckoutSession();
    };
    return parseResponse({ res, onSuccess });
  };

  const selectGiftPlan = async (plan: string) => {
    const res = await gifts.selectGiftPlan(plan);
    const onSuccess = () => {
      router &&
        !router.asPath.includes("/give-subscription/") &&
        analytics.giftSubscriptionStarted();
      return getGiftCheckoutSession();
    };
    return parseResponse({ res, onSuccess });
  };

  const setChildInfo = (personalization: api.ChildInfo) =>
    dispatch({
      type: "setCheckoutSession",
      session: { ...state.session, personalization },
    });

  const updateChildInfo = async (
    personalization: api.ChildInfo,
    toastOnError = false
  ) => {
    const res = await api.updateChildInfo(personalization);
    const onSuccess = async () => {
      setChildInfo(personalization);
      analytics.submitAgeDetails(res.age);
      setSuggestedClubs(res);
    };
    return parseResponse({ res, onSuccess, toastOnError });
  };

  const setOrderSummary = (orderSummary: api.OrderSummary) =>
    dispatch({ type: "setOrderSummary", orderSummary });

  const getOrderSummary = async () => {
    const res = await api.getOrderSummary();
    const onSuccess = () => {
      setOrderSummary(res);
    };
    return parseResponse({ res, onSuccess });
  };

  const setSelectedClub = (selectedClub?: string) =>
    dispatch({
      type: "setCheckoutSession",
      session: { ...state.session, selectedClub },
    });

  const setClubOnOrderSummary = (selectedClub: string) => {
    /* optimistic update for order summary */
    if (state.orderSummary?.orderSummary[0]?.clubSlug) {
      const updatedOrderSummary = cloneDeep(state.orderSummary);
      updatedOrderSummary.orderSummary[0].clubSlug = selectedClub;
      setOrderSummary(updatedOrderSummary);
    }
  };

  const updateSelectedClub = async (selectedClub: string) => {
    const res = await api.updateSelectedClub(selectedClub);
    const onSuccess = () => {
      setSelectedClub(selectedClub);
      setClubOnOrderSummary(selectedClub);
      getOrderSummary();
    };
    return parseResponse({ res, onSuccess });
  };

  const addPaymentMethod = async (
    paymentType: string,
    paymentToken: string
  ) => {
    const res = await api.addPaymentMethod(paymentType, paymentToken);
    return parseResponse({ res, toastOnError: true });
  };

  const addPromoCode = async (code: string) => {
    const res = await api.addPromoCode(code);
    const onSuccess = async () => {
      await getOrderSummary();
      await getCheckoutSession();
    };
    return parseResponse({ res, onSuccess });
  };

  const removePromoCode = async () => {
    const res = await api.removePromoCode();
    const onSuccess = async () => {
      await getOrderSummary();
      await getCheckoutSession();
    };
    return parseResponse({ res, onSuccess });
  };

  const getPromos = async (codes: string[]) => {
    const res = await api.getPromos(codes);
    return parseResponse({ res });
  };

  const validateShippingAddress = async (address: api.AddressInformation) => {
    const res = await api.validateAddress(address);
    const onSuccess = () => null;
    return parseResponse({ res, onSuccess });
  };

  const setShippingAddress = (shippingAddress?: api.AddressInformation) =>
    dispatch({
      type: "setCheckoutSession",
      session: { ...state.session, shippingAddress },
    });

  const saveShippingAddress = async (
    address: api.AddressInformation,
    itemId?: string
  ) => {
    const res = await api.saveAddress(address, itemId);
    const onSuccess = () => {
      setShippingAddress(address);
      getCheckoutSession();
      getOrderSummary();
    };
    return parseResponse({ res, onSuccess });
  };

  const purchaseSubscription = async (
    successCallback?: (
      res: api.PurchaseSubscriptionResponse
    ) => Promise<unknown>,
    options?: {
      estimatedLTV?: string | number;
      skipAddress?: boolean;
      suppressAutoRoute?: boolean;
    }
  ) => {
    const res = await api.purchaseSubscription(
      getExperiments(),
      options?.skipAddress
    );

    const invoice = (res as api.PurchaseSubscriptionInvoice[])?.[0]?.invoice;
    const url = router?.route;
    if (invoice && url) {
      facebookPixelTrackPurchase({
        estimatedLTV: options?.estimatedLTV,
        invoice,
        url,
      });
    }

    const onSuccess = async () => {
      let invoice;
      if (Array.isArray(res)) {
        invoice = res[0].invoice;
      } else {
        invoice = res.invoice;
      }
      successCallback && (await successCallback(res));
      refreshPaymentMethods?.();
      return Boolean(options?.suppressAutoRoute)
        ? undefined
        : router.push(`/confirmation/${invoice.id}`);
    };

    return parseResponse({ res, onSuccess, toastOnError: true });
  };

  const createDummyAccount = async (
    accountInformation: accountAPI.DummyAccountInformation,
    toastOnError = false
  ) => {
    const res = await accountAPI.createDummyAccount(accountInformation);
    const onSuccess = async () => await getCheckoutSession();
    return parseResponse({ res, onSuccess, toastOnError });
  };

  const getGiftOrderSummary = async () => {
    const res = await gifts.getGiftOrderSummary();
    const onSuccess = () => {
      setOrderSummary(res);
    };
    return parseResponse({ res, onSuccess });
  };

  const addGiftPaymentMethod = async (
    paymentType: string,
    paymentToken: string,
    authToken: string
  ) => {
    const res = await gifts.addPaymentMethod(
      paymentType,
      paymentToken,
      authToken
    );
    return parseResponse({ res, toastOnError: true });
  };

  const purchaseGift = async () => {
    const res = await gifts.purchaseGift();
    const onSuccess = () => {
      let invoice;
      if (Array.isArray(res)) {
        invoice = res[0].invoice;
      } else {
        invoice = res.invoice;
      }
      analytics.giftSubscriptionPurchased(invoice);
      router.push(`/gift-confirmation/${invoice.id}`);
    };
    return parseResponse({ res, onSuccess, toastOnError: true });
  };

  const addGiftPromoCode = async (code: string) => {
    const res = await gifts.addGiftPromoCode(code);
    const onSuccess = async () => {
      await getGiftOrderSummary();
    };
    return parseResponse({ res, onSuccess });
  };

  const setSelectedPlan = (selectedPlan?: string) =>
    dispatch({
      type: "setCheckoutSession",
      session: { ...state.session, selectedPlan },
    });

  const updateSelectedPlan = async ({
    plan,
    subscription,
  }: {
    plan: SubscriptionPlanSlug;
    subscription?: unknown;
  }) => {
    const res = await api.updateSelectedPlan({ plan, subscription });
    const onSuccess = async () => {
      await getOrderSummary();
      setSelectedPlan(plan);
    };
    return parseResponse({ res, onSuccess });
  };

  const updateExistingPlan = async ({
    plan,
    subscription,
  }: {
    plan: string;
    subscription: unknown;
  }) => {
    const res = await api.updateExistingPlan({ plan, subscription });
    const onSuccess = () => res;
    return parseResponse({ res, onSuccess });
  };

  const updateDeliverySchedule = async ({ schedule }: { schedule: string }) => {
    const res = await api.updateDeliverySchedule({ schedule });
    return parseResponse({ res });
  };

  /** @todo - remove `as any` once `AccountContext` is typed */
  const { auth } = useContext(AccountContext) as any;

  // This is the dummy user ID associated with the checkout session
  const sessionId = state?.session?.accountInfo?.amplitudeId;
  // If there is a true user ID from auth use that, otherwise use the dummy user id
  const userId = auth?.user?.amplitudeId ?? sessionId;

  const sessionType = useMemo(() => {
    if (router?.asPath.includes("/give-subscription/")) {
      return "gift";
    } else if (router?.asPath.includes("/subscribe/")) {
      return "subscription";
    } else {
      return null;
    }
  }, [router]);

  const sessionFetchers = useRef({
    gift: getGiftCheckoutSession,
    subscription: getCheckoutSession,
  });

  useEffect(() => {
    sessionType && sessionFetchers.current[sessionType]();
  }, [router, auth, sessionType]);

  /**
   * We need to set the Amplitude ID as soon as it is available so that all of the
   * user properties are associated with the new ID
   */
  useEffect(() => {
    if (userId) {
      analytics.setAmplitudeUserId(userId);
    }
  }, [userId]);

  const value = useMemo(
    () => ({
      ...state,
      getSuggestedClubs,
      getSuggestedGiftClubs,
      updateSelectedClub,
      addPaymentMethod,
      addPromoCode,
      removePromoCode,
      getPromos,
      updateChildInfo,
      getOrderSummary,
      purchaseSubscription,
      validateShippingAddress,
      saveShippingAddress,
      createDummyAccount,
      getGiftOrderSummary,
      addGiftPaymentMethod,
      selectGiftPlan,
      setGiftDetails,
      addGiftPromoCode,
      getCheckoutSession,
      getGiftCheckoutSession,
      purchaseGift,
      updateSelectedPlan,
      updateExistingPlan,
      updateDeliverySchedule,
    }),
    [state]
  );

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutProvider;
