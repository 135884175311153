import {
  PrepaidSubscriptionPlan,
  SnakeToCamelCaseNested,
} from "@literati/kids-api";
import { FormikSubmitStep } from "components/Step/stepTypes";
import { SubscriptionPlanCamelCase } from "lib/api/subscription";

export const SUBSCRIPTION_PLAN_SLUGS = {
  KEEP_ALL_MONTHLY: "keep-all-monthly",
  PREPAID_1_MONTH_0224: "prepaid-1-month-0224",
  PREPAID_3_MONTH_0224: "prepaid-3-month-0224",
  PREPAID_6_MONTH_0224: "prepaid-6-month-0224",
  PREPAID_12_MONTH_0224: "prepaid-12-month-0224",
  PREPAID_MONTHLY: "prepaid-monthly",
  PREPAID_THREE_BOOKS_0822: "prepaid-three-books-0822",
  TBYB_ANNUAL_2023_06: "annual-tbyb-2023-06",
  TBYB_ANNUAL_INC_20: "annual-tbyb-inc-20",
  TBYB_ANNUAL_INC_20_CREDITS_60: "annual-tbyb-inc-20-60",
  TBYB_MONTHLY: "one-month",
  TBYB_MONTHLY_2025: "one-month-2025",
  UNLIMITED_ANNUAL_0423: "unlimited-annual-0423",
  UNLIMITED_MONTHLY_995: "unlimited-monthly-995",
  UNLIMITED_MONTHLY_0922: "unlimited-monthly-0922",
  UNLIMITED_MONTHLY_0423: "unlimited-monthly-0423",
} as const;

export const SubscriptionPlanSlugValues = Object.values(
  SUBSCRIPTION_PLAN_SLUGS
);

export type SubscriptionPlanSlug = typeof SubscriptionPlanSlugValues[number];

export function isSubscriptionPlanSlug(
  value?: string
): value is SubscriptionPlanSlug {
  return SubscriptionPlanSlugValues.includes(value as SubscriptionPlanSlug);
}

export type Plans = {
  annual?: SubscriptionPlanCamelCase;
  monthly?: SubscriptionPlanCamelCase;
  monthly2025?: SubscriptionPlanCamelCase;
};

export type PlanPrepaidCamelCase =
  SnakeToCamelCaseNested<PrepaidSubscriptionPlan>;

export const USER_ENTRY_COUPON_INCOMPATIBLE_PLAN_SLUGS = [
  "prepaid-3-month-0224",
  "prepaid-6-month-0224",
  "prepaid-12-month-0224",
  "annual-tbyb-inc-20-60",
] as const;
export type UserEntryCouponIncompatiblePlanSlug =
  typeof USER_ENTRY_COUPON_INCOMPATIBLE_PLAN_SLUGS[number];
export function isUserEntryCouponIncompatiblePlanSlug(
  value?: string
): value is UserEntryCouponIncompatiblePlanSlug {
  return USER_ENTRY_COUPON_INCOMPATIBLE_PLAN_SLUGS.includes(
    value as UserEntryCouponIncompatiblePlanSlug
  );
}

export const PLAN_PREPAID_SLUGS = [
  "prepaid-3-month-0224",
  "prepaid-6-month-0224",
  "prepaid-12-month-0224",
  "prepaid-monthly",
] as const;
export type PlanPrepaidSlug = typeof PLAN_PREPAID_SLUGS[number];
export function isPlanPrepaidSlug(value?: string): value is PlanPrepaidSlug {
  return PLAN_PREPAID_SLUGS.includes(value as PlanPrepaidSlug);
}
export type PlanPrepaid = Omit<PlanPrepaidCamelCase, "slug"> & {
  slug: PlanPrepaidSlug;
  savingsAmount?: string;
};

export enum SelectPlanKey {
  KIND = "kind",
  PLAN_SLUG = "planSlug",
}
export type SelectPlanKeyEnumValue = `${SelectPlanKey}`;
export type SelectPlanValues = {
  [SelectPlanKey.KIND]: "select-plan";
  [SelectPlanKey.PLAN_SLUG]: string;
};
export type SelectPlanSubmit = FormikSubmitStep<SelectPlanValues>;
