import { SwipeStatus } from "components/bookShuffle/bookShuffleTypes";
import { SwipeStatusAction } from "components/bookShuffle/useSwipe";
import * as amplitude from "@amplitude/analytics-browser";

/** Helpful for mapping `SwipeStatus` decisions to event copy */
export const LikeDislikeMap = Object.freeze({
  [SwipeStatus.ACCEPTED]: "Like",
  [SwipeStatus.REJECTED]: "Dislike",

  // should not occur
  [SwipeStatus.UNDECIDED]: "Undecided",
});

export interface BookShuffleAmplitudeEventData {
  /** Example: "Book Shuffle - Clicked Card Details" */
  eventName: string;
  /** Example: "Read More" */
  label: string;
  userAction?: "click" | "swipe";
}

/**
 * Tracks primary Book Shuffle events in Amplitude.
 *
 * @see https://amplitude.github.io/Amplitude-JavaScript/#amplitudeclientlogevent
 * @see https://docs.google.com/spreadsheets/d/188YJPm1NN0APyYGoDiM3gysDpL_mh-3cOB64WA8jiB4/edit#gid=1322870746
 */
export function trackBookShuffleEvent({
  eventName,
  label,
  userAction,
}: BookShuffleAmplitudeEventData) {
  if (amplitude) {
    amplitude.track(eventName, {
      "event source": "literati-next-kids",
      "page type": "Book Shuffle",
      "user action": userAction,
      path: window.location.pathname,
      label,
    });
  }
}

/** Tracks when a Book Shuffle card is liked/disliked (accepted/rejected) */
export function trackBookShuffleCardDecision(
  swipeStatus: SwipeStatus,
  swipeStatusAction: SwipeStatusAction
) {
  const likeOrDislikeCopy = LikeDislikeMap[swipeStatus];

  trackBookShuffleEvent({
    eventName: `Clicked`,
    label: `${likeOrDislikeCopy} Book`,
    userAction: swipeStatusAction,
  });
}
