import React from "react";
import { ThemeProvider } from "styled-components";
import * as theme from "theme";

export interface ThemeProps {
  children: React.ReactNode;
}

/**
 * The default theme provider for the kids app.
 * Useful to reset color conflicts with the when the public-shared-ui-react styling
 *
 */
export const DefaultThemeProvider = ({ children }: ThemeProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
export default DefaultThemeProvider;
