import { makeUrl } from "utils";
import { without } from "lodash";
import Head from "next/head";

const Favicons = ({ favicons }) => {
  const defaultFavicon = favicons.find((f) => f.size.includes(64)) || {};
  const otherFavicons = without(favicons, defaultFavicon);

  return (
    <Head>
      {defaultFavicon && <link rel="icon" href={makeUrl(defaultFavicon.url)} />}
      {otherFavicons.map((f) => (
        <link
          key={f.url}
          rel="apple-touch-icon"
          sizes={f.size.join("x")}
          href={makeUrl(f.url)}
        />
      ))}
    </Head>
  );
};

export default Favicons;
