import { createContext, useContext, useEffect, useState } from "react";
import useSWR from "swr";
import * as authApi from "lib/api/auth";
import analytics from "analytics";
import useResponseParser from "utils/hooks/useResponseParser";
import { capitalize, merge } from "lodash";
import { useRouter } from "next/router";
import { login } from "lib/api/account";
import { AdminContext } from "contexts/AdminProvider";
import LogoutModal from "components/authenticate/LogoutModal/LogoutModal";

export const AuthContext = createContext({
  user: {},
  isAuthenticated: false,
  loginWithSocial: (payload) => Promise.resolve(payload),
  loginWithSession: (payload) => Promise.resolve(payload),
  loginWithPassword: (payload) => Promise.resolve(payload),
  linkProvider: (payload) => Promise.resolve(payload),
  openLogoutModal: (_) => undefined,
  closeLogoutModal: (_) => undefined,
  logout: (next) => Promise.resolve(next),
});

/**
 *
 * @param {Object} props
 * @param {ReactNode} [props.children]
 * @returns
 */
export const AuthProvider = ({ children }) => {
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const router = useRouter();

  // todo: request that message not be sent for sign in.
  // this call is required to display and clear the message queue
  const { refreshMessages, refreshFeatureFlags } = useContext(AdminContext);
  // this call needs to run at least once to get the csrftoken cookie
  useEffect(() => {
    authApi.getAuth();
  }, []);

  const { data: auth, mutate: refreshAuth } = useSWR(
    "authApi.getAuth",
    authApi.getAuth
  );

  const onLoginSuccess = async () => {
    refreshAuth(auth);
    refreshFeatureFlags?.();
  };

  const onSocialLoginError = (res, provider, token) => {
    if (res.status === 403) {
      return {
        providerData: { provider, token, email: res.error?.extraData?.email },
      };
    }
    return res;
  };

  const parseResponse = useResponseParser();

  const linkProvider = async ({
    providerData,
    onSuccess,
    ...handlers
  } = {}) => {
    const res = await authApi.linkProvider(providerData);
    return parseResponse({
      res,
      successMessage: `Successfully linked your ${capitalize(
        providerData.provider
      )} account!`,
      onSuccess: async () => {
        await updateAuthSocialAccounts(res);
        await onSuccess?.();
      },
      ...handlers,
    });
  };

  /* optimistic update for social accounts */
  const updateAuthSocialAccounts = (res) =>
    merge(auth, { user: { socialAccounts: res } });

  const unlinkProvider = async ({ payload, onSuccess, ...handlers }) => {
    const res = await authApi.unlinkProvider(payload);
    return parseResponse({
      res,
      onSuccess: async () => {
        await refreshAuth(
          updateAuthSocialAccounts({ [payload.provider]: null })
        );
        await onSuccess?.();
      },
      successMessage: `Successfully unlinked your ${capitalize(
        payload.provider
      )} account!`,
      ...handlers,
    });
  };

  const loginWithToken = async ({ payload, onSuccess, ...handlers }) => {
    let successMessage;
    let res = await authApi.loginWithToken(payload);
    if (res.loggedIn) {
      let res = await authApi.getAuth();
      // mutate SWR auth call with response to immediately update the auth value
      refreshAuth(res);
      successMessage = `Successfully signed in as ${res.email}`;
    }

    const handleSuccess = async () => {
      await onLoginSuccess();
      await onSuccess?.();
    };

    return parseResponse({
      res,
      successMessage,
      onSuccess: handleSuccess,
      ...handlers,
    });
  };

  const loginWithPassword = async ({
    login,
    password,
    onSuccess,
    ...handlers
  }) => {
    return loginWithToken({
      payload: {
        login,
        password,
        grant_type: "password",
      },
      onSuccess,
      ...handlers,
    });
  };

  const loginWithSocial = async ({ token, provider, ...handlers }) => {
    const res = await loginWithToken({
      payload: {
        provider,
        external_token: token,
        grant_type: "social",
      },
      toastOnError: false,
      ...handlers,
    });

    if (res.error) {
      return onSocialLoginError(res, provider, token);
    }
    return res;
  };

  const validatePassword = async ({ login, password, ...handlers }) => {
    const payload = {
      login,
      password,
      grant_type: "password",
    };
    const res = await authApi.validatePassword(payload);
    return parseResponse({
      res,
      ...handlers,
    });
  };

  const logout = async (next) => {
    await authApi.logout();
    setLogoutModalOpen(false);
    refreshAuth();
    return router.push(next || "/");
  };

  const loginWithSession = async (
    credentials,
    { onSuccess, ...handlers } = {}
  ) => {
    const res = await login(credentials);
    return parseResponse({
      res,
      ...handlers,
      onSuccess: (res) => {
        onLoginSuccess();
        // server queues a success message that needs to be displayed and cleared
        refreshMessages();
        onSuccess?.(res);
      },
      errorMessage: res?.error?.message,
    });
  };

  const openLogoutModal = () => setLogoutModalOpen(true);
  const closeLogoutModal = () => setLogoutModalOpen(false);

  const value = {
    ...auth,
    auth,
    refreshAuth,
    loginWithSocial,
    loginWithPassword,
    linkProvider,
    unlinkProvider,
    logout,
    loginWithSession,
    onLoginSuccess,
    validatePassword,
    openLogoutModal,
  };

  useEffect(() => {
    if (auth) {
      analytics.userAuthenticated(auth);
    }
  }, [auth]);

  return (
    <AuthContext.Provider value={value}>
      {children}
      <LogoutModal
        isOpen={logoutModalOpen}
        onClose={closeLogoutModal}
        onLogout={logout}
      />
    </AuthContext.Provider>
  );
};

export default AuthProvider;
