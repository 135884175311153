const breakpoints = {
  xs: 375,
  sm: 480,
  md: 720,
  lg: 1024,
  xl: 1440,
  max: 1600,
};

const BreakpointKey = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",
  MAX: "max",
};

const mapBreakpoints = (template, templateMax) => {
  const results = {};
  Object.keys(breakpoints).forEach((key) => {
    const value = breakpoints[key];
    results[key] = template(value);
    results["max:" + key] = templateMax(value);
  });
  return results;
};

/** for tailwind config */
const screenTemplate = (bp) => `${bp}px`;
const screenTemplateMax = (bp) => ({ max: `${bp - 1}px` });
const screens = mapBreakpoints(screenTemplate, screenTemplateMax);

/** for media queries */
const mediaTemplate = (bp) => `@media (min-width: ${bp}px)`;
const mediaTemplateMax = (bp) => `@media (max-width: ${bp - 1}px)`;
const media = mapBreakpoints(mediaTemplate, mediaTemplateMax);

module.exports = {
  BreakpointKey,
  breakpoints,
  screens,
  media,
};
