import Head from "next/head";
import { useContext, useEffect, useState } from "react";
import { AccountContext } from "contexts/AccountProvider";

const Profitwell = () => {
  const { email } = useContext(AccountContext);
  const [hasStarted, setHasStarted] = useState(false);
  const isProfitwellLanding =
    typeof window !== "undefined"
      ? window.location?.search?.includes("profitwell=1")
      : false;
  useEffect(() => {
    if (email) {
      window.profitwell && window.profitwell("user_email", email);
    } else if (!hasStarted) {
      setHasStarted(true);
      window.profitwell && window.profitwell("start", {});
    }
  }, [email, hasStarted, isProfitwellLanding]);

  if (!isProfitwellLanding && !email) {
    return null;
  }
  return (
    <Head>
      <script
        type="text/javascript"
        id="profitwell-js"
        data-pw-auth="bf99ddbdc5edf174497abe8b42a77c62"
        dangerouslySetInnerHTML={{
          __html: `
          (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
          a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
          s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
          })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
      `,
        }}
      />
    </Head>
  );
};

export default Profitwell;
