export const FacebookPixelEventName = {
  ADD_PAYMENT_INFO: "AddPaymentInfo",
  COMPLETE_REGISTRATION: "CompleteRegistration",
  PURCHASE: "Purchase",
  SUBSCRIBE: "Subscribe",
};

export const FacebookPixelFormatFunction = {
  [FacebookPixelEventName.ADD_PAYMENT_INFO]: formatPixelEventAddPaymentInfo,
  [FacebookPixelEventName.COMPLETE_REGISTRATION]:
    formatPixelEventCompleteRegistration,
  [FacebookPixelEventName.PURCHASE]: formatPixelEventPurchase,
  [FacebookPixelEventName.SUBSCRIBE]: formatPixelEventSubscribe,
};

const getTimeInSeconds = () => Math.floor(Date?.now() / 1000);

/**
 * Formats event data and calls the facebook pixel track function.
 *
 * @see https://app.shortcut.com/literati/story/52442/add-custom-facebook-pixel-events-on-the-browser
 * @see https://developers.facebook.com/docs/meta-pixel
 * @see https://developers.facebook.com/docs/meta-pixel/reference#object-properties
 *
 * @param {Object} args
 * @param {Object} args.fbq - function exposed by pixel for tracking events
 * @param {Object} args.eventData - optional event data passed through to underlying format functions
 * @param {String} args.eventName - one of FacebookPixelEventType
 */
export function facebookPixelTrackEvent({ fbq, eventData, eventName }) {
  if (!fbq) {
    console.error("facebookPixelTrackEvent: fbq is undefined");
    return null;
  }

  const formatFunction = FacebookPixelFormatFunction[eventName];
  if (!formatFunction) {
    console.warn(
      "facebookPixelTrackEvent: event type does not map to format function"
    );
    return null;
  }

  const eventParameters = formatFunction(eventData);
  if (!eventParameters) {
    // we omit sending if we have incomplete data and in those cases rely on server event
    return null;
  }

  const eventID = eventParameters?.event_id;
  return fbq("track", eventName, eventParameters, { eventID });
}

/**
 * Formats data for the add payment info event to be sent to FB.
 *
 * @param {Object} args
 * @param {String} args.paymentMethodId
 * @param {String} args.url
 */
export function formatPixelEventAddPaymentInfo({ paymentMethodId, url }) {
  if (!paymentMethodId || !url) {
    console.warn(
      `facebookPixelTrackEvent: parameter data incomplete for ${FacebookPixelEventName.ADD_PAYMENT_INFO} event`
    );
    return null;
  }

  return {
    action_source: "website",
    event_id: paymentMethodId, // @todo - discuss with Casi, what value is this?
    event_source_url: url,
    event_time: getTimeInSeconds(),
  };
}

/**
 * Submits the facebook pixel add payment info event.
 *
 * @param {Object} args
 * @param {String} args.paymentMethodId
 * @param {String} args.url
 */
export function facebookPixelTrackAddPaymentInfo({ paymentMethodId, url }) {
  if (!window.fbq) return;
  return facebookPixelTrackEvent({
    fbq,
    eventData: {
      paymentMethodId,
      url,
    },
    eventName: FacebookPixelEventName.ADD_PAYMENT_INFO,
  });
}

/**
 * Formats data for the complete registration event to be sent to FB.
 *
 * @param {Object} args
 * @param {String} args.url
 * @param {String} args.userId
 */
export function formatPixelEventCompleteRegistration({ url, userId }) {
  if (!url || !userId) {
    console.warn(
      `facebookPixelTrackEvent: parameter data incomplete for ${FacebookPixelEventName.COMPLETE_REGISTRATION} event`
    );
    return null;
  }

  return {
    action_source: "website",
    event_id: userId,
    event_source_url: url,
    event_time: getTimeInSeconds(),
  };
}

/**
 * Submits the facebook pixel complete registration event.
 *
 * @param {Object} args
 * @param {String} args.url
 * @param {String} args.userId
 */
export function facebookPixelTrackCompleteRegistration({ url, userId }) {
  if (!window.fbq) return;
  return facebookPixelTrackEvent({
    fbq,
    eventData: {
      url,
      userId,
    },
    eventName: FacebookPixelEventName.COMPLETE_REGISTRATION,
  });
}

/**
 * Formats data for the gift purchase event to be sent to FB.
 *
 * @param {Object} args
 * @param {String|Number} [args.estimatedLTV]
 * @param {Object} args.invoice
 * @param {String} args.invoice.id
 * @param {Number} args.invoice.orderValue
 * @param {String} args.url
 */
export function formatPixelEventPurchase({ estimatedLTV, invoice, url }) {
  if (!invoice?.id || !invoice?.orderValue || !url) {
    console.warn(
      `facebookPixelTrackEvent: parameter data incomplete for ${FacebookPixelEventName.PURCHASE} event`
    );
    return null;
  }

  return {
    action_source: "website",
    content_type: "Subscription",
    currency: "USD",
    value: estimatedLTV,
    event_id: invoice.id,
    event_source_url: url,
    event_time: getTimeInSeconds(),
  };
}

/**
 * Submits the facebook pixel purchase event.
 * This currently corresponds to either a regular subscription purchase
 * or a gift purchase.
 *
 * @param {Object} args
 * @param {String|Number} [args.estimatedLTV]
 * @param {Object} args.invoice
 * @param {String} args.url
 */
export function facebookPixelTrackPurchase({ estimatedLTV, invoice, url }) {
  if (!window.fbq) return;
  return facebookPixelTrackEvent({
    fbq,
    eventData: {
      estimatedLTV,
      invoice,
      url,
    },
    eventName: FacebookPixelEventName.PURCHASE,
  });
}

/**
 * Formats data for the subscription event to be sent to FB.
 *
 * @param {Object} args
 * @param {String|Number} [args.estimatedLTV]
 * @param {Object} args.invoice
 * @param {String} args.invoice.id
 * @param {Number} args.invoice.orderValue
 * @param {String} args.url
 */
export function formatPixelEventSubscribe({ estimatedLTV, invoice, url }) {
  if (!invoice?.id || !invoice?.orderValue || !url) {
    console.warn(
      `facebookPixelTrackEvent: parameter data incomplete for ${FacebookPixelEventName.SUBSCRIBE} event`
    );
    return null;
  }

  return {
    action_source: "website",
    currency: "USD",
    estimated_ltv: estimatedLTV,
    currency: "USD",
    event_id: invoice.id,
    event_source_url: url,
    event_time: getTimeInSeconds(),
    value: invoice.orderValue,
  };
}

/**
 * Submits the facebook pixel subscribe event.
 *
 * @param {Object} args
 * @param {String|Number} [args.estimatedLTV]
 * @param {String} args.invoice
 * @param {String} args.url
 */
export function facebookPixelTrackSubscribe({ estimatedLTV, invoice, url }) {
  if (!window.fbq) return;
  return facebookPixelTrackEvent({
    fbq,
    eventData: {
      estimatedLTV,
      invoice,
      url,
    },
    eventName: FacebookPixelEventName.SUBSCRIBE,
  });
}

/**
 * Submits the facebook pixel budget value response event
 *
 * @param {Object} args - data to send to facebook
 * @param {String} args.userId - id of user
 * @param {url} args.url - current url
 */
export function facebookPixelTrackBudgetValueResponse({ userId, url }) {
  if (!window.fbq) {
    return null;
  }

  const eventData = {
    action_source: "website",
    event_time: getTimeInSeconds(),
  };

  if (userId) {
    eventData.user_id = userId;
  }

  if (url) {
    eventData.event_source_url = url;
  }

  return fbq("trackCustom", "BudgetValueResponse", eventData);
}

export default facebookPixelTrackEvent;
